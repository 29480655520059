* {
  margin: 0;
  padding: 0; }

ul {
  list-style: none; }

input, button {
  font-family: inherit !important;
  outline: none;
  color: inherit; }

.__freshspire-loading-spinner {
  animation: spin 3000ms linear infinite; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

body {
  padding-top: env(safe-area-inset-top); }

.container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  min-height: calc(100vh - env(safe-area-inset-top));
  background-color: #f2f3f3; }

.react-confirm-alert-overlay {
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.5) !important; }
  .react-confirm-alert-overlay .alert-box {
    max-width: 400px;
    margin: 0 8px; }

.menu-bar {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  top: 0;
  top: env(safe-area-inset-top);
  left: 0;
  height: 100vh;
  height: calc(100vh - env(safe-area-inset-top));
  min-height: 633px;
  width: 250px;
  background-color: #51d79a;
  transition: all 0.1s; }
  .menu-bar .divider {
    background: #fff;
    opacity: 0.3; }
  .menu-bar .topBar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px; }
    .menu-bar .topBar .freshspire-logo {
      max-width: 10%;
      max-height: 10%;
      transform: scale(10); }
  .menu-bar .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    flex-direction: column;
    width: 100%;
    margin: 10px 0 20px; }
    .menu-bar .profile .picture-container {
      position: relative;
      overflow: hidden;
      transition: all 0.1s; }
      .menu-bar .profile .picture-container .profile-picture {
        border-radius: 100%;
        width: 80px;
        height: 80px;
        object-fit: cover; }
    .menu-bar .profile .name {
      transition: all 0.1s;
      color: #fff;
      font-size: 1.2em;
      font-family: "MuseoSans500";
      max-width: 180px;
      text-align: center;
      margin-top: 4px; }
  .menu-bar .bottom {
    margin-top: auto;
    margin-bottom: 20px; }
  .menu-bar .active {
    background-color: rgba(255, 255, 255, 0.15); }
  .menu-bar .list-item {
    padding-left: 24px;
    color: #fff; }
    .menu-bar .list-item .list-text {
      color: #fff; }
    .menu-bar .list-item .list-icon {
      color: #fff; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .menu-bar {
      z-index: 10; }
      .menu-bar.close {
        width: 50px; }
        .menu-bar.close .list-text {
          opacity: 0;
          pointer-events: none; }
        .menu-bar.close .list-container {
          margin-left: -13px; }
        .menu-bar.close .topBar .freshspire-logo {
          opacity: 0; }
        .menu-bar.close .profile .picture-container .profile-picture {
          width: 30px;
          height: 30px;
          object-fit: cover; }
        .menu-bar.close .profile .name {
          font-size: 0em;
          opacity: 0;
          height: 0; } }
  @media (min-width: 0) and (max-width: 549px) {
    .menu-bar {
      display: none; } }

.bar-toggle {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 200px;
  top: 0;
  top: env(safe-area-inset-top);
  background-color: #51d79a;
  color: #fff;
  width: 50px;
  height: 50px;
  transition: all 0.1s; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .bar-toggle {
      z-index: 10;
      display: flex; }
      .bar-toggle.close {
        left: 0px; } }
  @media (min-width: 0) and (max-width: 549px) {
    .bar-toggle {
      display: none; } }

.menu-fab {
  position: fixed;
  display: none;
  bottom: 0;
  z-index: 3;
  width: 100vw;
  pointer-events: none; }
  .menu-fab .fab-overlay {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.274);
    z-index: -1;
    transition: all 0.3s;
    opacity: 0; }
    .menu-fab .fab-overlay.open {
      opacity: 1;
      pointer-events: auto; }
  .menu-fab .top-logo {
    display: none;
    text-align: center;
    position: relative;
    width: auto;
    margin: 0 auto;
    z-index: 11;
    margin-bottom: 15px;
    pointer-events: none; }
    .menu-fab .top-logo .fab-background {
      transition: all 0.3s;
      position: absolute;
      border-radius: 100%;
      width: 4rem;
      height: 4rem;
      z-index: -1;
      box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.15);
      background: white; }
    .menu-fab .top-logo .close-icon {
      margin: auto auto 0;
      position: relative;
      bottom: 0;
      width: 4rem;
      height: 4rem;
      display: none;
      pointer-events: all; }
      .menu-fab .top-logo .close-icon .icon {
        margin: auto;
        font-size: 35px;
        color: #51d79a; }
    .menu-fab .top-logo img {
      width: 4rem;
      pointer-events: all; }
      .menu-fab .top-logo img.open {
        box-shadow: none;
        opacity: 0;
        display: none; }
    .menu-fab .top-logo.open {
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 0;
      display: flex; }
      .menu-fab .top-logo.open .fab-background {
        transform: scale(17); }
      .menu-fab .top-logo.open .close-icon {
        display: flex;
        margin-bottom: 15px; }
    .menu-fab .top-logo .dropdown-menu {
      display: none !important;
      transition: all 0.3s;
      pointer-events: all; }
      @media screen and (max-width: 549px) {
        .menu-fab .top-logo .dropdown-menu {
          position: fixed;
          flex-direction: column;
          bottom: 0;
          left: 50%;
          width: 100%;
          height: 0;
          z-index: -1;
          overflow: hidden;
          transform: translateX(-50%);
          -ms-flex-pack: center;
          justify-content: center;
          margin-bottom: 0; } }
      @media screen and (min-width: 550px) {
        .menu-fab .top-logo .dropdown-menu {
          display: flex;
          justify-content: space-around;
          margin-top: 2rem;
          font-weight: 700; } }
      @media screen and (max-width: 549px) {
        .menu-fab .top-logo .dropdown-menu.open {
          height: auto;
          margin-bottom: 74px;
          padding-top: 40px; } }
      .menu-fab .top-logo .dropdown-menu li.menu-item {
        margin: 0 auto 10px;
        opacity: 0;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #c9c9c9;
        width: 50%;
        font-size: 1.1rem;
        text-align: center;
        font-family: 'MuseoSans300', 'sans-serif';
        color: #51d79a;
        cursor: pointer; }
        .menu-fab .top-logo .dropdown-menu li.menu-item.open {
          opacity: 1;
          -webkit-animation: fade-in .3s .3s ease-out 1 normal backwards, slide-in-up .2s .3s ease-out 1 normal backwards;
          -moz-animation: fade-in .3s .3s ease-out 1 normal backwards, slide-in-up .2s .3s ease-out 1 normal backwards;
          -o-animation: fade-in .3s .3s ease-out 1 normal backwards, slide-in-up .2s .3s ease-out 1 normal backwards;
          animation: fade-in .3s .3s ease-out 1 normal backwards, slide-in-up .2s .3s ease-out 1 normal backwards; }
        @media screen and (min-width: 550px) {
          .menu-fab .top-logo .dropdown-menu li.menu-item {
            margin-right: 1rem; } }
      @media (min-width: 0) and (max-width: 549px) {
        .menu-fab .top-logo .dropdown-menu {
          display: block !important; } }
  @media (min-width: 0) and (max-width: 549px) {
    .menu-fab {
      display: flex; }
      .menu-fab .top-logo {
        display: block; } }

.form-container {
  height: 100%;
  width: 100%; }
  .form-container .deal-form-wrapper {
    border-radius: 70px;
    margin-top: 40px;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    font-size: 14px;
    width: 78%;
    max-width: 600px;
    min-width: 490px; }
    .form-container .deal-form-wrapper .form__group {
      background-color: white;
      padding-bottom: auto;
      background-size: auto;
      width: auto;
      padding: 1em;
      max-width: 100%; }
      .form-container .deal-form-wrapper .form__group .helper-text {
        margin-top: -8px;
        margin-bottom: 20px; }
      .form-container .deal-form-wrapper .form__group .form__control {
        padding: 40px 40px 40px 40px; }
        .form-container .deal-form-wrapper .form__group .form__control .unit-label {
          padding-top: 1.5em;
          padding-left: 1em; }
        .form-container .deal-form-wrapper .form__group .form__control .checkbox-grid-container {
          position: relative; }
          .form-container .deal-form-wrapper .form__group .form__control .checkbox-grid-container .label {
            width: 80%;
            min-width: 90px;
            padding-bottom: 10px;
            margin-right: 0; }
          @media (min-width: 0) and (max-width: 600px) {
            .form-container .deal-form-wrapper .form__group .form__control .checkbox-grid-container {
              width: 85%;
              min-width: 270px; } }
          @media (min-width: 0) and (max-width: 400px) {
            .form-container .deal-form-wrapper .form__group .form__control .checkbox-grid-container .label {
              width: 85%; } }
      @media (min-width: 0) and (max-width: 400px) {
        .form-container .deal-form-wrapper .form__group {
          padding: 0px; }
          .form-container .deal-form-wrapper .form__group .form__control {
            transform: scale(0.9);
            padding: 0; } }
    @media (min-width: 0) and (max-width: 549px) {
      .form-container .deal-form-wrapper {
        margin-top: 0; } }
    @media (min-width: 0) and (max-width: 530px) {
      .form-container .deal-form-wrapper {
        width: 100%;
        min-width: 0px; } }
  .form-container .btn-submit-sign {
    margin-top: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .form-container .btn-submit-sign .action {
      height: 2.5rem;
      width: 120px;
      color: white;
      font-size: 0.9rem;
      text-transform: none;
      margin-bottom: 30px; }
    .form-container .btn-submit-sign .notice {
      margin-top: 10px;
      max-width: 230px;
      text-align: center;
      font-size: 0.9em;
      opacity: 0.6; }
    @media (min-width: 0) and (max-width: 530px) {
      .form-container .btn-submit-sign button.action {
        border-bottom-left-radius: 34px !important;
        border-bottom-right-radius: 34px !important; }
      .form-container .btn-submit-sign button.logout-web-ft {
        margin-top: 30px;
        margin-bottom: 20px; } }

.nav-appbar {
  height: 80px;
  width: 100%; }
  .nav-appbar .nav-container {
    height: 100%; }
    .nav-appbar .nav-container .freshspire-logo {
      height: 100%;
      width: auto; }
  @media (max-width: 767px) {
    .nav-appbar {
      height: 60px; } }

.topbar {
  position: fixed;
  padding-left: 250px;
  z-index: 2;
  width: 100%; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .topbar {
      padding-left: 50px; } }
  @media (min-width: 0) and (max-width: 549px) {
    .topbar {
      padding-left: 0; } }
  .topbar .appbar {
    background-color: #fff; }
    .topbar .appbar .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .topbar .appbar .text .back-arrow-link {
        color: #000; }
        .topbar .appbar .text .back-arrow-link .back-arrow {
          display: none;
          position: absolute; }
      .topbar .appbar .text .main {
        margin-top: auto;
        margin-left: 45px; }
        .topbar .appbar .text .main .icon-wrapper {
          display: flex;
          flex-direction: row;
          position: absolute;
          right: 0;
          margin-right: 30px;
          cursor: pointer; }
          .topbar .appbar .text .main .icon-wrapper #menuAnchor {
            position: absolute;
            top: 30px; }
          .topbar .appbar .text .main .icon-wrapper a {
            display: flex;
            flex-direction: row; }
          .topbar .appbar .text .main .icon-wrapper .icon {
            font-size: 1em;
            color: rgba(0, 0, 0, 0.87); }
            .topbar .appbar .text .main .icon-wrapper .icon.mobil-ft {
              display: none; }
              @media (min-width: 0) and (max-width: 549px) {
                .topbar .appbar .text .main .icon-wrapper .icon.mobil-ft {
                  display: block; } }
          .topbar .appbar .text .main .icon-wrapper .badge {
            margin-right: 15px;
            pointer-events: none; }
            .topbar .appbar .text .main .icon-wrapper .badge.extra {
              margin-right: 25px; }
            .topbar .appbar .text .main .icon-wrapper .badge.hidden {
              visibility: hidden; }
          .topbar .appbar .text .main .icon-wrapper .total {
            font-size: 0.9em; }
          @media (max-width: 767px) {
            .topbar .appbar .text .main .icon-wrapper {
              margin-right: 20px; } }
          @media (max-width: 424px) {
            .topbar .appbar .text .main .icon-wrapper {
              margin-right: 10px; }
              .topbar .appbar .text .main .icon-wrapper .total {
                font-size: 0.8em; } }
      .topbar .appbar .text .subtext {
        margin-left: 45px;
        font-size: 1.2em;
        color: #4e4e4e; }
      .topbar .appbar .text .button-wrapper {
        display: flex;
        z-index: 2;
        height: 0;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end; }
        @media (min-width: 1280px) {
          .topbar .appbar .text .button-wrapper {
            padding-right: 405px; }
            .topbar .appbar .text .button-wrapper.fixed {
              padding-right: 0; } }
        .topbar .appbar .text .button-wrapper .button {
          bottom: -40px;
          text-transform: none; }
          .topbar .appbar .text .button-wrapper .button .icon {
            font-size: 1em; }
    .topbar .appbar .tabs {
      margin-left: 69px;
      min-height: 48px; }
      .topbar .appbar .tabs .breadcrumb {
        min-width: 0px;
        opacity: 1; }
      .topbar .appbar .tabs .tab-item {
        text-transform: none; }
        @media (max-width: 424px) {
          .topbar .appbar .tabs .tab-item {
            font-size: 0.8em; } }
    @media (max-width: 767px) {
      .topbar .appbar .text .button-wrapper .button.tablet-hide {
        display: none; } }

@media (max-width: 767px) and (min-width: 0) and (max-width: 549px) {
  .topbar .appbar .text .button-wrapper {
    display: none; } }
    @media (min-width: 0) and (max-width: 549px) {
      .topbar .appbar .text {
        flex-direction: row;
        padding-left: 0;
        align-items: center; }
        .topbar .appbar .text .back-arrow.return {
          display: block;
          margin-top: -24px; }
        .topbar .appbar .text .main {
          margin-top: 0;
          margin-left: 54px; }
        .topbar .appbar .text .subtext {
          display: none; }
        .topbar .appbar .text .button .dev-assist {
          position: absolute;
          top: 15px;
          left: 200px; }
      .topbar .appbar .tabs {
        display: none; }
        .topbar .appbar .tabs.mobil-ft {
          display: block;
          margin: auto; }
        .topbar .appbar .tabs.lg-margin {
          margin-top: 40px; }
      .topbar .appbar .web-ft {
        display: none; } }

.bottom-Appbar {
  display: none; }
  .bottom-Appbar .invisible {
    cursor: default;
    pointer-events: none;
    margin-left: 3vw; }
  .bottom-Appbar .squish {
    cursor: default;
    pointer-events: none;
    padding: 0 !important; }

.notification-menu div:nth-child(2) {
  top: 56px !important; }

.notification-menu .notification-container {
  display: flex;
  height: auto !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important; }
  .notification-menu .notification-container .notification-item {
    display: flex;
    width: 100%; }
    .notification-menu .notification-container .notification-item .avatar {
      margin-left: -10px;
      margin-right: 10px; }
    .notification-menu .notification-container .notification-item .text-wrapper {
      margin-right: auto;
      width: 100%; }
      .notification-menu .notification-container .notification-item .text-wrapper .heading {
        display: flex;
        justify-content: space-between; }
        .notification-menu .notification-container .notification-item .text-wrapper .heading .subject {
          padding-right: 8px;
          text-decoration: none !important;
          white-space: normal; }
        .notification-menu .notification-container .notification-item .text-wrapper .heading .time {
          text-decoration: none !important;
          white-space: normal; }
      .notification-menu .notification-container .notification-item .text-wrapper .message {
        text-decoration: none !important;
        white-space: normal;
        font-size: 0.8em; }
    .notification-menu .notification-container .notification-item .closeicon {
      margin: 0;
      margin-left: 20px;
      margin-right: -7px; }

.notification-menu .notificaiton-action {
  display: flex;
  min-width: 400px;
  justify-content: flex-end; }
  .notification-menu .notificaiton-action .clear-button {
    margin: auto;
    width: 108px;
    height: 37px; }

@media (min-width: 0) and (max-width: 549px) {
  .bottom-Appbar {
    display: block; }
  .notification-menu .notificaiton-action {
    min-width: 80vw; } }

.bottom-Appbar {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%; }
  .bottom-Appbar.highest-stack {
    z-index: 3; }

.search-bar {
  display: flex; }
  .search-bar.row-fix {
    flex-direction: row; }
  .search-bar.column-fix {
    flex-direction: column; }
  .search-bar .filter-button {
    margin-top: 15px;
    text-transform: none; }
  .search-bar .search-wrapper {
    margin: 1rem 0 1rem 0;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    width: 100%; }
    .search-bar .search-wrapper .expansion-container {
      display: flex;
      flex-grow: 1; }
      .search-bar .search-wrapper .expansion-container .input-container {
        width: 100%;
        border-radius: 0.5rem;
        box-shadow: none;
        cursor: pointer;
        background-color: #fff; }
        .search-bar .search-wrapper .expansion-container .input-container .expansion-top {
          display: flex;
          min-height: 3rem;
          max-height: 3rem;
          width: 100%; }
          .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-label {
            display: none;
            margin: auto 0;
            font-family: "MuseoSans500";
            font-size: 1.2em; }
          .search-bar .search-wrapper .expansion-container .input-container .expansion-top .search-icon {
            display: flex;
            align-items: center;
            height: 90%;
            position: absolute;
            pointer-events: none;
            margin-left: 5px; }
          .search-bar .search-wrapper .expansion-container .input-container .expansion-top input {
            padding: 0 1.7rem;
            padding-left: 2.6rem;
            border: none;
            outline: none;
            height: 2.6rem;
            flex-grow: 1;
            font-size: 1.3em;
            min-width: 60px;
            cursor: pointer; }
          .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-dropdown {
            display: none;
            justify-content: center;
            align-items: center;
            height: 48px;
            width: 48px; }
          @media (max-width: 1279px) {
            .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-label, .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-dropdown {
              display: flex; }
              .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-label.short, .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-dropdown.short {
                display: none; } }
    @media (max-width: 1279px) and (min-width: 0) and (max-width: 549px) {
      .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-label.short, .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-dropdown.short {
        display: flex; } }
        .search-bar .search-wrapper .expansion-container .input-container .expansion-bottom {
          display: none;
          padding: 0;
          padding-bottom: 20px;
          flex-direction: column; }
        @media (max-width: 1279px) {
          .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-label, .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-dropdown {
            display: flex; }
          .search-bar .search-wrapper .expansion-container .input-container .expansion-bottom {
            display: flex; } }
    .search-bar .search-wrapper .sort-by-container {
      display: flex;
      flex-direction: row;
      margin-bottom: auto; }
      .search-bar .search-wrapper .sort-by-container .sort-label {
        font-family: "MuseoSans700";
        min-width: 80px;
        margin: auto;
        text-align: center; }
      .search-bar .search-wrapper .sort-by-container .sort-box {
        display: flex;
        padding: 0 0.8rem;
        margin-right: 0.5rem;
        align-items: center;
        border-radius: 0.5rem;
        height: 3rem;
        min-width: 150px;
        background-color: #fff;
        border: none;
        outline: none;
        font-size: 1rem;
        font-family: "MuseoSans500";
        cursor: pointer; }
      .search-bar .search-wrapper .sort-by-container.mobile {
        display: none; }
  .search-bar .filter-wrapper {
    margin: 1rem 0 2rem 0;
    min-width: 400px;
    display: none; }
    .search-bar .filter-wrapper .filter-container {
      position: fixed;
      display: flex;
      flex-direction: column;
      margin-left: 1.7rem;
      border-radius: 0.5rem;
      width: 350px;
      background-color: #fff;
      overflow: hidden;
      font-family: "MuseoSans500"; }
      .search-bar .filter-wrapper .filter-container .header {
        display: flex;
        align-items: center;
        margin: 0;
        padding-left: 20px;
        background-color: #51d79a;
        height: 50px;
        color: #fff; }
        .search-bar .filter-wrapper .filter-container .header .text {
          font-size: 0.8em;
          color: #fff; }
      .search-bar .filter-wrapper .filter-container .body {
        margin-top: 20px;
        margin-bottom: 20px; }
    @media (min-width: 1280px) {
      .search-bar .filter-wrapper {
        display: block; } }
  .search-bar .checkbox-group-label {
    margin-top: 10px; }
    .search-bar .checkbox-group-label .text {
      font-family: "MuseoSans700"; }
  .search-bar .checkbox-wrapper {
    display: flex;
    justify-content: center;
    border: 1px solid #51d79a;
    border-radius: 0.5rem; }
    .search-bar .checkbox-wrapper .checkbox-label {
      width: 130px;
      height: 36px; }
      .search-bar .checkbox-wrapper .checkbox-label .checkbox {
        width: 36px; }
  @media (min-width: 0) and (max-width: 549px) {
    .search-bar {
      z-index: 2;
      position: fixed;
      margin-top: -13px;
      overflow-y: hidden;
      padding-bottom: 10px;
      width: 100%; }
      .search-bar.expanded {
        z-index: 4; }
      .search-bar .search-wrapper {
        background-color: #fff;
        position: relative;
        margin: 0;
        margin-top: 2px;
        padding: 0 1rem 0.5rem; }
        .search-bar .search-wrapper.shadow {
          box-shadow: 0px 5px 4px -1px rgba(0, 0, 0, 0.09), 0px 5px 5px 0px rgba(0, 0, 0, 0.08), 0px 5px 10px 0px rgba(0, 0, 0, 0.05); }
        .search-bar .search-wrapper .expansion-container {
          max-height: 90vh;
          border: 1px solid #51d79a;
          border-radius: 0.5rem; }
          .search-bar .search-wrapper .expansion-container.scroll {
            overflow-y: scroll; }
          .search-bar .search-wrapper .expansion-container .input-container {
            box-shadow: none; }
            .search-bar .search-wrapper .expansion-container .input-container .expansion-top {
              padding: 0; }
              .search-bar .search-wrapper .expansion-container .input-container .expansion-top .filter-label {
                display: none; }
              .search-bar .search-wrapper .expansion-container .input-container .expansion-top .search-icon {
                margin-left: 20px; }
              .search-bar .search-wrapper .expansion-container .input-container .expansion-top input {
                width: 60px;
                margin-right: 50px;
                margin-left: 3.4rem;
                padding: 0 1rem;
                transition: 0.1s;
                border-bottom: 1px solid #3dd28f00; }
                .search-bar .search-wrapper .expansion-container .input-container .expansion-top input.opened {
                  border-bottom: 1px solid #3dd28f; }
            .search-bar .search-wrapper .expansion-container .input-container .expansion-bottom .checkbox-group-label {
              max-width: 100%;
              flex-basis: 100%;
              padding-left: 30px;
              padding-right: 30px;
              background: #f5f5f5; }
              .search-bar .search-wrapper .expansion-container .input-container .expansion-bottom .checkbox-group-label .text {
                font-size: 1rem;
                font-family: "MuseoSans700" !important; }
            .search-bar .search-wrapper .expansion-container .input-container .expansion-bottom .checkbox-wrapper {
              display: flex;
              justify-content: center;
              border: none;
              border-radius: 0.5rem;
              max-width: 100%;
              flex-basis: 100%; }
              .search-bar .search-wrapper .expansion-container .input-container .expansion-bottom .checkbox-wrapper.mobil-margin {
                margin: 10px 10px 0; }
              .search-bar .search-wrapper .expansion-container .input-container .expansion-bottom .checkbox-wrapper .checkbox-label {
                width: 130px;
                height: 39px; }
                .search-bar .search-wrapper .expansion-container .input-container .expansion-bottom .checkbox-wrapper .checkbox-label .checkbox {
                  width: 39px; }
        .search-bar .search-wrapper .sort-by-container {
          margin: 10px 0; }
          .search-bar .search-wrapper .sort-by-container .sort-label {
            margin: auto 0;
            text-align: left;
            margin-left: 30px; }
          .search-bar .search-wrapper .sort-by-container .sort-box {
            box-shadow: none;
            background-color: none;
            margin-right: 10%;
            border: 1px solid #51d79a;
            flex-grow: 1; }
          .search-bar .search-wrapper .sort-by-container.mobile {
            display: flex; }
          .search-bar .search-wrapper .sort-by-container.web {
            display: none; } }

.loading-spin-wrapper {
  margin: auto; }
  .loading-spin-wrapper .loading-spin-page {
    position: relative;
    top: -130px; }
    @media (max-width: 767px) {
      .loading-spin-wrapper .loading-spin-page {
        top: -100px; } }
    @media (max-width: 424px) {
      .loading-spin-wrapper .loading-spin-page {
        top: -35px; } }

.item-wrapper {
  margin: 8px 10px 8px; }
  .item-wrapper .item-container {
    background: #fff;
    border-radius: 10px;
    overflow: hidden; }
  .item-wrapper .item-info {
    display: flex;
    margin: 0 auto 0 auto;
    margin-left: auto;
    padding: 0;
    border-radius: 10px !important; }
    .item-wrapper .item-info .image-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      min-width: 70px;
      overflow: hidden;
      margin: 8px;
      border-radius: 20px; }
      .item-wrapper .item-info .image-container .item-img {
        max-width: 12%;
        max-height: 12%;
        transform: scale(12); }
    .item-wrapper .item-info .info-section {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1; }
      .item-wrapper .item-info .info-section .top-info {
        display: flex;
        flex-basis: 100%; }
        .item-wrapper .item-info .info-section .top-info .major-text {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          margin: 10px 0; }
          .item-wrapper .item-info .info-section .top-info .major-text .seller {
            margin-right: auto;
            color: #51d79a; }
            .item-wrapper .item-info .info-section .top-info .major-text .seller:hover {
              text-decoration: underline;
              cursor: pointer; }
            .item-wrapper .item-info .info-section .top-info .major-text .seller .text_icon {
              font-size: 14px; }
          .item-wrapper .item-info .info-section .top-info .major-text .name-container {
            max-width: 310px;
            margin-right: 10px; }
            @media (max-width: 767px) {
              .item-wrapper .item-info .info-section .top-info .major-text .name-container {
                max-width: none; } }
            .item-wrapper .item-info .info-section .top-info .major-text .name-container .date {
              opacity: 0.4; }
            .item-wrapper .item-info .info-section .top-info .major-text .name-container .name {
              margin-bottom: auto;
              font-family: "MuseoSans700";
              display: flex; }
              .item-wrapper .item-info .info-section .top-info .major-text .name-container .name:hover {
                text-decoration: underline;
                cursor: pointer; }
              .item-wrapper .item-info .info-section .top-info .major-text .name-container .name .lock-icon {
                display: inline-block !important;
                line-height: normal;
                margin-left: auto;
                opacity: 0.8;
                text-decoration: none; }
                @media (min-width: 768px) {
                  .item-wrapper .item-info .info-section .top-info .major-text .name-container .name .lock-icon {
                    display: none !important; } }
            .item-wrapper .item-info .info-section .top-info .major-text .name-container .label {
              font-size: 1.1em;
              line-height: 16px !important;
              text-decoration: underline;
              margin-bottom: 10px; }
          .item-wrapper .item-info .info-section .top-info .major-text .notes {
            margin-top: 5px;
            max-width: 310px;
            margin-right: 10px; }
        .item-wrapper .item-info .info-section .top-info .minor-text {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          width: auto; }
          .item-wrapper .item-info .info-section .top-info .minor-text .info-container {
            display: flex;
            flex-direction: column;
            min-width: 200px; }
            .item-wrapper .item-info .info-section .top-info .minor-text .info-container .subinfo {
              display: flex;
              flex-wrap: nowrap; }
              .item-wrapper .item-info .info-section .top-info .minor-text .info-container .subinfo .value {
                margin-left: auto; }
            .item-wrapper .item-info .info-section .top-info .minor-text .info-container .detail-container {
              justify-content: flex-end; }
              .item-wrapper .item-info .info-section .top-info .minor-text .info-container .detail-container .detail {
                margin-left: 10px; }
          .item-wrapper .item-info .info-section .top-info .minor-text .content-divider {
            height: 90%;
            margin-left: 10px;
            border-right: 1px solid #ddd; }
          .item-wrapper .item-info .info-section .top-info .minor-text .price {
            height: auto;
            text-align: center;
            min-width: 110px; }
            .item-wrapper .item-info .info-section .top-info .minor-text .price .price-container {
              display: flex;
              flex-wrap: nowrap;
              justify-content: center;
              margin-bottom: 9px; }
            .item-wrapper .item-info .info-section .top-info .minor-text .price .detail-container {
              display: flex;
              justify-content: center; }
              .item-wrapper .item-info .info-section .top-info .minor-text .price .detail-container .detail {
                margin-right: 10px; }
            @media (max-width: 768px) {
              .item-wrapper .item-info .info-section .top-info .minor-text .price .detail-button-container {
                display: none; } }
            .item-wrapper .item-info .info-section .top-info .minor-text .price .detail-button-container .action-button {
              min-height: 25px;
              max-height: 25px;
              padding: 0;
              text-transform: none;
              font-size: 1em; }
            .item-wrapper .item-info .info-section .top-info .minor-text .price .detail-button-container.top-mar {
              margin-top: 9px; }
          .item-wrapper .item-info .info-section .top-info .minor-text .detail-container {
            display: flex;
            flex-direction: row; }
            .item-wrapper .item-info .info-section .top-info .minor-text .detail-container .label {
              font-family: "MuseoSans300";
              font-size: 1.1em;
              color: #d75751; }
              .item-wrapper .item-info .info-section .top-info .minor-text .detail-container .label.divider {
                margin-left: 0; }
              .item-wrapper .item-info .info-section .top-info .minor-text .detail-container .label.hidden {
                display: none; }
      .item-wrapper .item-info .info-section .actions .details-btn {
        padding: 0;
        min-height: 30px;
        min-width: 75px;
        background-color: #51d79a;
        text-transform: none; }
        .item-wrapper .item-info .info-section .actions .details-btn .MuiButton-label-2 {
          font-size: 0.9em; }
    .item-wrapper .item-info .sale-wrapper {
      width: 0;
      height: 0;
      pointer-events: none; }
    .item-wrapper .item-info .sale-box {
      position: relative;
      z-index: 1;
      overflow: hidden;
      height: 100px;
      width: 100px;
      pointer-events: none;
      border-top-left-radius: 10px; }
      .item-wrapper .item-info .sale-box .on-sale {
        position: absolute;
        text-align: center;
        background: #51d79a;
        transform: rotate(-40deg);
        padding: 30px 60px 2px;
        top: -19px;
        left: -63px;
        font-family: "MuseoSans700";
        color: #fff; }
      .item-wrapper .item-info .sale-box.hidden {
        display: none; }
    .item-wrapper .item-info .mobil-ft {
      display: none !important; }
    .item-wrapper .item-info .label {
      color: #51d79a; }
    .item-wrapper .item-info .lg {
      font-size: 1.3em; }
    .item-wrapper .item-info .md {
      font-size: 1.2em; }
    .item-wrapper .item-info .sm {
      font-size: 1em; }
  .item-wrapper .order_date {
    padding-left: 123px; }
  .item-wrapper .status {
    padding-left: 90px; }
    .item-wrapper .status .chip {
      height: 25px;
      font-size: 1em;
      border-radius: 6px;
      margin-right: 20px;
      opacity: 0.7; }
      .item-wrapper .status .chip.null {
        border: 1px solid #777;
        background-color: rgba(116, 116, 116, 0);
        color: #777; }
      .item-wrapper .status .chip.positive {
        border: 1px solid #3dd28f;
        background-color: rgba(116, 116, 116, 0);
        color: #3dd28f; }
      .item-wrapper .status .chip.negative {
        border: 1px solid #d75751;
        background-color: rgba(116, 116, 116, 0);
        color: #d75751; }
  .item-wrapper .button-container {
    padding: 0 8px 8px;
    padding-right: 3%;
    display: flex;
    justify-content: flex-end; }
    .item-wrapper .button-container .btn-border {
      border-right: 1px solid #747474;
      margin: auto 4px;
      height: 15px; }
    .item-wrapper .button-container .action-button {
      font-size: 1.3em;
      text-transform: none; }
      .item-wrapper .button-container .action-button.mobil {
        display: none; }
    .item-wrapper .button-container.hidden {
      display: none; }
  @media (max-width: 767px) {
    .item-wrapper .item-info .info-section .top-info {
      flex-wrap: wrap;
      margin-right: 10px; }
      .item-wrapper .item-info .info-section .top-info .major-text {
        flex-basis: 100%;
        margin: 10px 0 4px; }
        .item-wrapper .item-info .info-section .top-info .major-text .name-container .seller:hover {
          text-decoration: none;
          cursor: default; }
        .item-wrapper .item-info .info-section .top-info .major-text .name-container .seller .text_icon {
          display: none; }
      .item-wrapper .item-info .info-section .top-info .minor-text {
        flex-basis: 100%;
        flex-wrap: wrap; }
        .item-wrapper .item-info .info-section .top-info .minor-text .info-container {
          margin-right: auto;
          max-width: 200px;
          border: none; }
        .item-wrapper .item-info .info-section .top-info .minor-text .price {
          display: flex;
          height: auto;
          flex-basis: 100%;
          flex-direction: row-reverse;
          justify-content: flex-end;
          min-width: 0; }
          .item-wrapper .item-info .info-section .top-info .minor-text .price .price-container {
            margin-left: auto;
            margin-right: 0;
            margin-bottom: 0; }
          .item-wrapper .item-info .info-section .top-info .minor-text .price .detail-button-container.top-mar {
            margin-top: 0; }
    .item-wrapper .item-info .web-ft {
      display: none !important; }
    .item-wrapper .item-info .mobil-ft {
      display: flex !important; }
    .item-wrapper .item-info .label {
      color: #51d79a; }
    .item-wrapper .item-info .lg {
      font-size: 1.3em; }
    .item-wrapper .item-info .sm {
      font-size: 0.97em; }
    .item-wrapper .order_date {
      margin: 10px 0;
      padding-left: 0; }
    .item-wrapper .status {
      margin: 10px 0;
      padding-left: 0;
      display: flex;
      justify-content: flex-end; }
      .item-wrapper .status .chip {
        height: 20px;
        font-size: 0.7em;
        margin-right: 20px; }
    .item-wrapper .button-container {
      padding: 0 8px 8px;
      padding-right: 10px;
      display: flex;
      justify-content: flex-end; }
      .item-wrapper .button-container .action-button {
        min-height: 25px;
        max-height: 25px;
        padding: 0;
        text-transform: none;
        font-size: 1.1em; }
        .item-wrapper .button-container .action-button.mobil {
          display: block; } }
  @media (max-width: 424px) {
    .item-wrapper .item-info .info-section .top-info {
      flex-wrap: wrap; }
      .item-wrapper .item-info .info-section .top-info .major-text {
        flex-basis: 100%;
        margin: 10px 0 0; }
      .item-wrapper .item-info .info-section .top-info .minor-text {
        flex-basis: 100%;
        flex-wrap: wrap; }
        .item-wrapper .item-info .info-section .top-info .minor-text .info-container {
          flex-basis: 100%;
          min-width: 0;
          max-width: 1000px; }
        .item-wrapper .item-info .info-section .top-info .minor-text .price {
          display: flex;
          height: auto;
          flex-basis: 100%;
          flex-direction: row-reverse;
          justify-content: flex-end; }
    .item-wrapper .item-info .web-ft {
      display: none !important; }
    .item-wrapper .item-info .label {
      color: #51d79a; }
    .item-wrapper .item-info .lg {
      font-size: 1.1em; }
    .item-wrapper .item-info .md {
      font-size: 1em; }
    .item-wrapper .item-info .sm {
      font-size: 0.8em; }
    .item-wrapper .status .chip {
      margin-right: 10px; }
    .item-wrapper .button-container {
      padding: 0 8px 8px;
      padding-right: 10px;
      display: flex;
      justify-content: flex-end; }
      .item-wrapper .button-container .action-button {
        min-height: 25px;
        max-height: 25px;
        padding: 3px;
        text-transform: none;
        font-size: 0.9em; } }
  .item-wrapper .hidden {
    display: none; }

.modal-generic {
  width: 90% !important;
  max-width: 800px !important;
  padding: 0 !important;
  background: rgba(0, 0, 0, 0) !important;
  border-style: none !important; }
  .modal-generic .label {
    color: #51d79a; }
  .modal-generic .inventory-button {
    text-transform: none; }
  .modal-generic .modal-scroll {
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    height: 100vh; }
    .modal-generic .modal-scroll .item-topbar {
      display: none;
      position: absolute;
      top: 0;
      top: env(safe-area-inset-top);
      width: 100%;
      z-index: 300; }
      .modal-generic .modal-scroll .item-topbar .appbar {
        background-color: #fff; }
        .modal-generic .modal-scroll .item-topbar .appbar .text {
          padding-left: 0; }
    .modal-generic .modal-scroll .bottom-Appbar {
      display: none; }
    .modal-generic .modal-scroll::-webkit-scrollbar {
      display: none; }
    .modal-generic .modal-scroll .popup {
      position: relative;
      width: 100%;
      margin: auto;
      margin-bottom: 0; }
      .modal-generic .modal-scroll .popup.float {
        margin-bottom: auto; }
      .modal-generic .modal-scroll .popup .modal-close-btn {
        position: absolute;
        right: 0; }
      .modal-generic .modal-scroll .popup .sale-box {
        height: 200px;
        width: 200px;
        border-top-left-radius: 20px;
        position: absolute;
        z-index: 1;
        overflow: hidden;
        pointer-events: none; }
        .modal-generic .modal-scroll .popup .sale-box .on-sale {
          padding: 40px 80px 2px;
          top: -16px;
          left: -84px;
          font-size: 2.2em;
          position: absolute;
          text-align: center;
          background: #51d79a;
          transform: rotate(-40deg);
          font-family: "MuseoSans700";
          color: #fff; }
        .modal-generic .modal-scroll .popup .sale-box.hidden {
          display: none; }
      .modal-generic .modal-scroll .popup .modal-container {
        display: flex;
        flex-wrap: wrap;
        padding: 48px 1rem 1.4rem;
        background-color: #fff;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px; }
        .modal-generic .modal-scroll .popup .modal-container.rounded {
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          margin-bottom: auto; }
        .modal-generic .modal-scroll .popup .modal-container .width-restrict {
          min-width: 158px !important;
          transform: scale(0.8); }
        .modal-generic .modal-scroll .popup .modal-container .deal-display {
          width: 40%;
          display: flex;
          flex-direction: column; }
          .modal-generic .modal-scroll .popup .modal-container .deal-display .img-crop {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 220px;
            max-width: 220px;
            height: 50vw;
            width: 50vw;
            margin: 0 auto !important;
            overflow: hidden; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .img-crop .modal-img {
              max-width: 10%;
              max-height: 10%;
              transform: scale(10);
              border-radius: 1.5px; }
          .modal-generic .modal-scroll .popup .modal-container .deal-display .chip-wrapper {
            display: flex;
            justify-content: center; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .chip-wrapper .chip {
              margin: 0 0.5rem; }
          .modal-generic .modal-scroll .popup .modal-container .deal-display .modal-title {
            text-align: center;
            font-size: 1.6em;
            margin-bottom: 7px; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .modal-title.profile-link .text_icon {
              font-size: 13px;
              margin-bottom: 4px;
              color: #51d79a; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .modal-title.profile-link:hover {
              text-decoration: underline;
              cursor: pointer; }
          .modal-generic .modal-scroll .popup .modal-container .deal-display .modal-seller {
            text-align: center;
            color: #51d79a;
            text-decoration: underline;
            font-size: 1.3em;
            margin: 6px 0; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .modal-seller .text_icon {
              font-size: 13px;
              margin-bottom: 4px; }
          .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers {
            margin: 10px 0 0;
            border: 1px solid #51d79a;
            padding: 10px 20px 0;
            border-radius: 10px; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls .quantity-input {
              width: 50px;
              text-align: center;
              border-style: none;
              font-size: 1.5em; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls input::-webkit-outer-spin-button,
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls input::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0;
              /* <-- Apparently some margin are still there even though it's hidden */ }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls .order-button {
              height: 32px;
              width: 32px;
              border-radius: 100px;
              border: 2px solid #51d79a;
              background: rgba(0, 0, 0, 0); }
              .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls .order-button .step-button {
                color: #51d79a;
                transform: scale(2); }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .needby {
              margin: 15px 0; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .date {
              border: 2px solid #51d79a;
              border-radius: 10px;
              min-width: 150px !important; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .total {
              font-size: 1.5em;
              color: #51d79a;
              font-family: "MuseoSans500"; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .transporation-text {
              font-family: "MuseoSans500";
              margin: 8px 0 10px; }
        .modal-generic .modal-scroll .popup .modal-container .info {
          display: flex;
          flex-direction: column;
          width: 55%;
          margin-left: auto; }
          .modal-generic .modal-scroll .popup .modal-container .info .top-info-modal {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal-generic .modal-scroll .popup .modal-container .info .top-info-modal .grid-item {
              display: flex;
              flex-direction: column;
              flex-grow: 1 !important;
              border-right: 1px solid #ddd; }
              .modal-generic .modal-scroll .popup .modal-container .info .top-info-modal .grid-item:last-child {
                border-style: none; }
              .modal-generic .modal-scroll .popup .modal-container .info .top-info-modal .grid-item .label, .modal-generic .modal-scroll .popup .modal-container .info .top-info-modal .grid-item .value {
                margin: auto; }
          .modal-generic .modal-scroll .popup .modal-container .info .details {
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal-generic .modal-scroll .popup .modal-container .info .details .label {
              margin-bottom: 10px; }
            .modal-generic .modal-scroll .popup .modal-container .info .details .grid-item {
              display: flex;
              flex-wrap: nowrap;
              padding: 6px 0; }
              .modal-generic .modal-scroll .popup .modal-container .info .details .grid-item .gd {
                margin-left: 16px; }
          .modal-generic .modal-scroll .popup .modal-container .info .reason-info {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal-generic .modal-scroll .popup .modal-container .info .reason-info .value {
              margin-left: 16px; }
          .modal-generic .modal-scroll .popup .modal-container .info .bottom {
            margin-bottom: 10px; }
            .modal-generic .modal-scroll .popup .modal-container .info .bottom .label {
              margin-bottom: 10px; }
          .modal-generic .modal-scroll .popup .modal-container .info .modal-buttons {
            margin-left: auto;
            margin-top: auto; }
            .modal-generic .modal-scroll .popup .modal-container .info .modal-buttons button {
              width: 85px;
              height: 30px;
              margin-left: 30px; }
            .modal-generic .modal-scroll .popup .modal-container .info .modal-buttons.hidden {
              display: none; }
        .modal-generic .modal-scroll .popup .modal-container .lg {
          font-size: 1.7em;
          font-family: "MuseoSans700"; }
        .modal-generic .modal-scroll .popup .modal-container .md {
          font-size: 1.2em;
          font-family: "MuseoSans300"; }
        .modal-generic .modal-scroll .popup .modal-container .sm {
          font-size: 1.1em;
          font-family: "MuseoSans300"; }
    .modal-generic .modal-scroll .submit-button-container {
      width: 100%;
      margin-bottom: auto; }
      .modal-generic .modal-scroll .submit-button-container .submit {
        width: 100%;
        height: 50px;
        font-size: 1.5em;
        font-family: "MuseoSans700" !important;
        text-transform: none;
        text-align: center;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px; }
      .modal-generic .modal-scroll .submit-button-container.hidden {
        display: none; }
  @media (max-width: 767px) {
    .modal-generic {
      max-width: 500px !important; }
      .modal-generic .modal-scroll .popup .modal-container {
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem; }
        .modal-generic .modal-scroll .popup .modal-container .deal-display {
          width: 100%;
          padding-top: 0; }
          .modal-generic .modal-scroll .popup .modal-container .deal-display .chip-wrapper {
            margin-bottom: 8px; }
          .modal-generic .modal-scroll .popup .modal-container .deal-display .modal-title {
            max-width: 300px;
            margin: 7px auto; }
          .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers {
            margin: 10px 0; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .label {
              margin-left: 30px; }
        .modal-generic .modal-scroll .popup .modal-container .info {
          width: 100%; }
          .modal-generic .modal-scroll .popup .modal-container .info .modal-buttons {
            display: none; }
        .modal-generic .modal-scroll .popup .modal-container .web-ft {
          display: none; }
        .modal-generic .modal-scroll .popup .modal-container .lg {
          font-size: 1.6em; } }
  @media (min-width: 0) and (max-width: 500px) {
    .modal-generic {
      width: 100vw !important; }
      .modal-generic .modal-scroll {
        padding-bottom: 0; }
        .modal-generic .modal-scroll .item-topbar {
          display: block; }
        .modal-generic .modal-scroll .bottom-Appbar {
          display: block; }
        .modal-generic .modal-scroll .popup {
          padding-bottom: 0;
          margin: 0; }
          .modal-generic .modal-scroll .popup .modal-close-btn {
            display: none; }
          .modal-generic .modal-scroll .popup .sale-box {
            top: 57px;
            border-top-left-radius: 0; }
            .modal-generic .modal-scroll .popup .sale-box .on-sale {
              top: -20px;
              left: -85px;
              font-size: 1.6em; }
          .modal-generic .modal-scroll .popup .modal-container {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            min-height: calc(100vh - 40px);
            margin: 40px 0 0;
            padding-bottom: 112px;
            padding-top: 56px;
            padding-left: 1rem;
            padding-right: 1rem;
            flex-grow: 1; }
            .modal-generic .modal-scroll .popup .modal-container .deal-display .order-controllers .label {
              margin-left: 0; }
            .modal-generic .modal-scroll .popup .modal-container.rounded {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0; }
      .modal-generic .submit-button-container {
        display: none;
        position: fixed;
        bottom: 0; }
        .modal-generic .submit-button-container .submit {
          border-radius: 0 !important;
          font-size: 1.3em !important; } }

.dropzone {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  max-width: 300px;
  max-height: 300px;
  min-width: 230px;
  min-height: 230px;
  height: auto;
  width: 25vw; }
  .dropzone .drop-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
    margin-bottom: 4px;
    max-width: 300px;
    max-height: 300px;
    min-width: 230px;
    min-height: 230px;
    height: 25vw;
    width: 25vw;
    overflow: hidden; }
    .dropzone .drop-box .placeholder {
      position: absolute;
      opacity: 0.3;
      text-align: center; }
      .dropzone .drop-box .placeholder .splash-icon {
        font-size: 70px; }
      .dropzone .drop-box .placeholder .splash-text {
        font-size: 1em; }
      .dropzone .drop-box .placeholder.hidden {
        display: none; }
    .dropzone .drop-box .drop-img {
      max-width: 10%;
      max-height: 10%;
      transform: scale(10); }
    .dropzone .drop-box .drop-clear-button {
      position: absolute;
      color: #fff;
      bottom: 0;
      right: 0;
      max-width: 40px;
      min-width: 40px;
      max-height: 30px;
      min-height: 30px;
      padding: 0;
      box-shadow: none; }
      .dropzone .drop-box .drop-clear-button .icon {
        font-size: 20px; }
      .dropzone .drop-box .drop-clear-button.hidden {
        display: none; }
  .dropzone .drop-open-button {
    max-height: 30px;
    min-height: 30px;
    padding: 0 8px;
    margin: auto;
    text-transform: none; }

.dropzone-avatar {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  top: 40px; }
  .dropzone-avatar .drop-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
    margin-bottom: 4px;
    margin: auto;
    border: 14px solid #fff;
    border-radius: 100%;
    height: 150px;
    width: 150px;
    overflow: hidden; }
    .dropzone-avatar .drop-box .placeholder {
      position: absolute;
      opacity: 0.3;
      text-align: center; }
      .dropzone-avatar .drop-box .placeholder .splash-icon {
        font-size: 20px; }
      .dropzone-avatar .drop-box .placeholder .splash-text {
        font-size: 1em; }
      .dropzone-avatar .drop-box .placeholder.hidden {
        display: none; }
    .dropzone-avatar .drop-box .drop-img {
      max-height: 10%;
      transform: scale(10); }
    .dropzone-avatar .drop-box .drop-clear-button {
      position: absolute;
      color: #fff;
      bottom: 0;
      right: 0;
      max-width: 40px;
      min-width: 40px;
      max-height: 30px;
      min-height: 30px;
      padding: 0;
      box-shadow: none; }
      .dropzone-avatar .drop-box .drop-clear-button .icon {
        font-size: 20px; }
      .dropzone-avatar .drop-box .drop-clear-button.hidden {
        display: none; }
  .dropzone-avatar .drop-clear-button {
    position: relative;
    color: #fff;
    margin-top: -38px;
    margin-bottom: 8px;
    margin-left: auto;
    max-width: 40px;
    min-width: 40px;
    max-height: 30px;
    min-height: 30px;
    padding: 0;
    box-shadow: none; }
    .dropzone-avatar .drop-clear-button .icon {
      font-size: 20px; }
    .dropzone-avatar .drop-clear-button.hidden {
      display: none; }
  .dropzone-avatar .drop-open-button {
    max-height: 30px;
    min-height: 30px;
    padding: 0 8px;
    margin: auto;
    margin-top: -30px;
    bottom: -30px;
    text-transform: none; }
  @media (max-width: 767px) {
    .dropzone-avatar .drop-box {
      border: 8px solid #fff;
      height: 140px;
      width: 140px; } }
  @media (min-width: 768px) {
    .dropzone-avatar .drop-open-button {
      display: none; } }

.client-list-container {
  font-family: 'MuseoSans300', 'sans-serif';
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  margin-left: 250px;
  margin-top: 112px; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .client-list-container {
      margin-left: 50px; } }
  @media (min-width: 0) and (max-width: 549px) {
    .client-list-container {
      margin-left: 0; } }
  @media (min-width: 0) and (max-width: 549px) {
    .client-list-container {
      margin-top: 56px;
      margin-bottom: 120px; } }
  .client-list-container .tool-tip-container {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px; }
  .client-list-container .client-list {
    margin: 0 0 80px; }
    .client-list-container .client-list .empty-text {
      display: flex;
      width: 100%; }
      .client-list-container .client-list .empty-text .text {
        margin: auto;
        opacity: 0.6;
        margin-top: 100px; }
        @media (max-width: 424px) {
          .client-list-container .client-list .empty-text .text {
            font-size: 1.5em;
            margin-top: 40px; } }
    .client-list-container .client-list .result-label {
      margin: 4px 10px 8px;
      font-size: 1.4em;
      opacity: 0.5; }
      .client-list-container .client-list .result-label.sm {
        font-size: 0.8em; }
  @media (min-width: 1280px) {
    .client-list-container .client-list.filter-present {
      padding-right: 400px; } }
  @media (min-width: 768px) {
    .client-list-container .tool-tip-container {
      display: none; } }
  @media (min-width: 0) and (max-width: 549px) {
    .client-list-container .tool-tip-container {
      display: none; }
    .client-list-container .client-list {
      margin-top: 50px;
      margin-bottom: 0; }
      .client-list-container .client-list.lg-margin {
        margin-top: 90px; }
      .client-list-container .client-list .result-label {
        margin: 8px 10px 8px;
        font-size: 1.2em; } }

.login-container {
  background-image: linear-gradient(135deg, rgba(82, 194, 143, 0.281) 0%, rgba(52, 189, 127, 0.363) 100%), url("../assets/sign-cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  display: flex;
  width: 100vw;
  overflow: hidden; }
  .login-container.scroll {
    height: auto; }
  .login-container .login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 60%;
    min-width: 768px; }
    .login-container .login-wrapper .img-wrapper {
      margin-top: 30%;
      width: 100%;
      text-align: center;
      pointer-events: none; }
      .login-container .login-wrapper .img-wrapper .img-format {
        margin-top: -30%;
        max-width: 10.5%;
        max-height: 10.5%;
        transform: scale(10.5); }
    .login-container .login-wrapper .credentials-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
      margin-bottom: 50px;
      min-width: 300px;
      max-width: 400px; }
      .login-container .login-wrapper .credentials-wrapper .loading-spin-wrapper {
        padding-top: 100px; }
      .login-container .login-wrapper .credentials-wrapper .login-credentials {
        display: flex;
        flex-direction: column; }
        .login-container .login-wrapper .credentials-wrapper .login-credentials.centered {
          align-items: center; }
        .login-container .login-wrapper .credentials-wrapper .login-credentials .label {
          color: #fff;
          margin-bottom: 6px;
          font-family: "MuseoSans300"; }
        .login-container .login-wrapper .credentials-wrapper .login-credentials input {
          background-color: rgba(248, 248, 248, 0.253);
          border-radius: 4px;
          padding: 12px;
          border: none;
          color: #fff;
          font-size: 17px;
          font-family: "MuseoSans300" !important;
          margin-bottom: 16px; }
          .login-container .login-wrapper .credentials-wrapper .login-credentials input::before {
            border-bottom: none !important; }
          .login-container .login-wrapper .credentials-wrapper .login-credentials input::after {
            border-bottom: none !important; }
          .login-container .login-wrapper .credentials-wrapper .login-credentials input::placeholder {
            color: rgba(255, 255, 255, 0.753);
            opacity: 0; }
        .login-container .login-wrapper .credentials-wrapper .login-credentials .phoneInput::before, .login-container .login-wrapper .credentials-wrapper .login-credentials .phoneInput::after {
          border-bottom: none; }
      .login-container .login-wrapper .credentials-wrapper .button-container {
        width: 200px;
        margin: 25px auto; }
        .login-container .login-wrapper .credentials-wrapper .button-container button {
          width: 100%;
          color: #fff;
          border: 1px solid #fff;
          text-transform: none;
          font-family: "MuseoSans700" !important; }
      .login-container .login-wrapper .credentials-wrapper .text-wrapper {
        margin: 0 auto;
        color: #fff; }
        .login-container .login-wrapper .credentials-wrapper .text-wrapper.right {
          margin: 0 0 0 auto;
          margin-top: -8px; }
        .login-container .login-wrapper .credentials-wrapper .text-wrapper.center {
          text-align: center; }
        .login-container .login-wrapper .credentials-wrapper .text-wrapper a {
          color: #fff;
          text-decoration: underline;
          font-family: "MuseoSans700"; }
    @media (max-width: 767px) {
      .login-container .login-wrapper {
        min-width: 425px; }
        .login-container .login-wrapper .img-wrapper {
          margin-top: 130px; }
          .login-container .login-wrapper .img-wrapper .img-format {
            max-width: 11%;
            max-height: 11%;
            transform: scale(11); }
        .login-container .login-wrapper .credentials-wrapper {
          min-height: 305px;
          min-width: 250px;
          max-width: 400px; } }
    @media (max-width: 424px) {
      .login-container .login-wrapper {
        width: 100%;
        min-width: 0; }
        .login-container .login-wrapper .img-wrapper {
          margin-top: 130px; }
          .login-container .login-wrapper .img-wrapper .img-format {
            margin-top: -40%;
            max-width: 9.5%;
            max-height: 9.5%;
            transform: scale(9.5); }
        .login-container .login-wrapper .credentials-wrapper {
          margin-top: 0; }
          .login-container .login-wrapper .credentials-wrapper .login-credentials .label {
            display: none; }
          .login-container .login-wrapper .credentials-wrapper .login-credentials input::placeholder {
            opacity: 1; } }

.forgot-container {
  background-image: linear-gradient(135deg, rgba(82, 194, 143, 0.281) 0%, rgba(52, 189, 127, 0.363) 100%), url("../assets/sign-cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }
  .forgot-container .forgot-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 60%;
    min-width: 768px; }
    .forgot-container .forgot-wrapper .img-wrapper {
      width: 100%;
      text-align: center;
      pointer-events: none; }
      .forgot-container .forgot-wrapper .img-wrapper .img-format {
        margin-top: -30%;
        max-width: 10.5%;
        max-height: 10.5%;
        transform: scale(10.5); }
    .forgot-container .forgot-wrapper .forgot-instructions {
      width: 90%;
      margin: 0 auto;
      margin-bottom: 5%;
      text-align: center;
      color: #fff;
      font-size: 17px;
      font-family: "MuseoSans300" !important; }
    .forgot-container .forgot-wrapper .credentials-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
      margin-bottom: -10%;
      min-width: 300px;
      max-width: 400px; }
      .forgot-container .forgot-wrapper .credentials-wrapper .forgot-credentials {
        display: flex;
        flex-direction: column; }
        .forgot-container .forgot-wrapper .credentials-wrapper .forgot-credentials .label {
          color: #fff;
          margin-bottom: 6px;
          font-family: "MuseoSans300"; }
        .forgot-container .forgot-wrapper .credentials-wrapper .forgot-credentials input {
          background-color: rgba(248, 248, 248, 0.253);
          border-radius: 4px;
          padding: 12px;
          border: none;
          color: #fff;
          font-size: 17px;
          font-family: "MuseoSans300" !important;
          margin-bottom: 16px; }
          .forgot-container .forgot-wrapper .credentials-wrapper .forgot-credentials input::before {
            border-bottom: none !important; }
          .forgot-container .forgot-wrapper .credentials-wrapper .forgot-credentials input::after {
            border-bottom: none !important; }
          .forgot-container .forgot-wrapper .credentials-wrapper .forgot-credentials input::placeholder {
            color: rgba(255, 255, 255, 0.753);
            opacity: 0; }
      .forgot-container .forgot-wrapper .credentials-wrapper .button-container {
        width: 200px;
        margin: 25px auto; }
        .forgot-container .forgot-wrapper .credentials-wrapper .button-container button {
          width: 100%;
          color: #fff;
          border: 1px solid #fff;
          text-transform: none;
          font-family: "MuseoSans700" !important; }
      .forgot-container .forgot-wrapper .credentials-wrapper .text-wrapper {
        margin: 0 auto;
        color: #fff; }
        .forgot-container .forgot-wrapper .credentials-wrapper .text-wrapper.right {
          margin: 0 0 0 auto;
          margin-top: -8px; }
        .forgot-container .forgot-wrapper .credentials-wrapper .text-wrapper.center {
          text-align: center; }
        .forgot-container .forgot-wrapper .credentials-wrapper .text-wrapper a {
          color: #fff;
          text-decoration: underline;
          font-family: "MuseoSans700"; }
    @media (max-width: 767px) {
      .forgot-container .forgot-wrapper {
        min-width: 425px; }
        .forgot-container .forgot-wrapper .img-wrapper .img-format {
          margin-top: -45%;
          max-width: 11%;
          max-height: 11%;
          transform: scale(11); }
        .forgot-container .forgot-wrapper .forgot-instructions {
          min-width: 250px;
          max-width: 400px; }
        .forgot-container .forgot-wrapper .credentials-wrapper {
          min-width: 250px;
          max-width: 400px; } }
    @media (max-width: 424px) {
      .forgot-container .forgot-wrapper {
        width: 100%;
        min-width: 0; }
        .forgot-container .forgot-wrapper .img-wrapper {
          margin-top: 50px; }
          .forgot-container .forgot-wrapper .img-wrapper .img-format {
            margin-top: -40%;
            max-width: 9.5%;
            max-height: 9.5%;
            transform: scale(9.5); }
        .forgot-container .forgot-wrapper .credentials-wrapper {
          margin-top: 0; }
          .forgot-container .forgot-wrapper .credentials-wrapper .forgot-credentials .label {
            display: none; }
          .forgot-container .forgot-wrapper .credentials-wrapper .forgot-credentials input::placeholder {
            opacity: 1; } }

.header {
  font-family: 'MuseoSans300', 'sans-serif';
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #4c4c4c; }
  @media screen and (min-width: 550px) {
    .header {
      margin-left: 7.5%; } }
  @media screen and (max-width: 549px) {
    .header {
      display: flex;
      justify-content: center;
      align-items: center; } }

.btn-basic {
  position: absolute;
  transform: translate(-50%, -130%);
  top: 100%;
  left: 3.5rem; }
  .btn-basic button {
    -webkit-appearance: none;
    padding: 0 0.8rem;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    height: 1.5rem;
    background-color: #ef594f;
    width: 5rem;
    color: white;
    border: none;
    outline: none;
    font-size: 1rem;
    cursor: pointer; }

.historys-container {
  display: flex;
  font-family: 'MuseoSans300', 'sans-serif';
  background-size: auto;
  width: 100%;
  margin-left: 250px;
  margin-top: 112px; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .historys-container {
      margin-left: 50px; } }
  @media (min-width: 0) and (max-width: 549px) {
    .historys-container {
      margin-left: 0; } }
  @media (min-width: 0) and (max-width: 549px) {
    .historys-container {
      margin-top: 100px;
      margin-bottom: 60px; } }
  .historys-container .btn-primary {
    border: none;
    background: none; }

.historys-list {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 60px;
  width: 100%;
  margin-top: 1%;
  flex-wrap: wrap; }
  .historys-list .empty-text {
    display: flex;
    width: 100%; }
    .historys-list .empty-text .text {
      margin: auto;
      opacity: 0.6;
      margin-top: 100px; }
      @media (max-width: 424px) {
        .historys-list .empty-text .text {
          font-size: 1.5em;
          margin-top: 40px; } }
  .historys-list .table-container {
    background-color: rgba(61, 210, 143, 0.123);
    display: flex;
    flex-direction: column;
    box-shadow: none; }
  .historys-list .table-summary {
    flex-grow: 1;
    background-color: #fff; }
  @media (min-width: 0) and (max-width: 600px) {
    .historys-list .bottom-info {
      margin-top: 3px; } }
  .historys-list .right-bound {
    margin-left: auto;
    background: #fff;
    padding: 8px;
    border-radius: 5px; }
  .historys-list .colored-text {
    color: #51d79a; }
    .historys-list .colored-text.spaced {
      margin-right: auto; }
  @media (max-width: 424px) {
    .historys-list .md {
      font-size: 1.1em; } }
  .historys-list .sm {
    display: flex;
    font-size: 1em; }
    @media (max-width: 767px) {
      .historys-list .sm {
        font-size: 0.9em; } }
    @media (max-width: 424px) {
      .historys-list .sm {
        font-size: 0.8em; } }
  .historys-list .product-list {
    display: flex;
    flex-direction: column;
    height: 100% !important;
    padding-bottom: 0; }
    .historys-list .product-list .info-container {
      border: none;
      border-bottom: 1px solid rgba(61, 210, 143, 0.123);
      border-radius: 5px;
      margin-bottom: 3px;
      background-color: #fff; }
    .historys-list .product-list .summary {
      border-radius: 5px;
      margin-top: 10px; }
    .historys-list .product-list .appeal-button {
      margin-left: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      min-height: 25px;
      max-height: 25px;
      padding: 0 4px;
      text-transform: none; }

.Profile-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin-left: 250px;
  margin-top: 112px;
  margin-bottom: 120px; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .Profile-container {
      margin-left: 50px; } }
  @media (min-width: 0) and (max-width: 549px) {
    .Profile-container {
      margin-left: 0; } }
  @media (min-width: 0) and (max-width: 549px) {
    .Profile-container {
      margin-top: 56px; } }
  .Profile-container .mobil-ft {
    display: none; }
  .Profile-container .web-ft {
    display: block; }
  .Profile-container .loading-spin-wrapper .loading-spin-page {
    top: 40px; }
  .Profile-container .top-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    margin: auto; }
    .Profile-container .top-container .backdrop {
      display: flex;
      background-image: url("./../assets/Cover.jpg");
      height: 10rem; }
    .Profile-container .top-container .info-container {
      display: flex;
      background-color: rgba(255, 255, 255, 0);
      width: 100%;
      padding: 0 30px;
      max-width: 1100px;
      margin: auto auto 0; }
      .Profile-container .top-container .info-container .avatar {
        position: relative;
        top: 40px;
        z-index: 1; }
        .Profile-container .top-container .info-container .avatar img {
          border: 14px solid #fff;
          border-radius: 100%;
          width: 150px;
          height: 150px;
          object-fit: cover; }
      .Profile-container .top-container .info-container .text-container {
        z-index: 1;
        margin-top: auto;
        flex-grow: 1;
        display: flex;
        justify-content: space-between; }
        .Profile-container .top-container .info-container .text-container .business-name {
          font-size: 2.2em;
          font-family: "MuseoSans700";
          padding-left: 30px;
          margin-right: 20px; }
        .Profile-container .top-container .info-container .text-container .request-button-container {
          display: flex;
          margin-left: auto; }
        .Profile-container .top-container .info-container .text-container .request-button {
          min-width: 110px;
          margin-top: auto;
          margin-left: auto;
          margin-bottom: 10px;
          margin-right: 20px;
          min-height: 35px;
          max-height: 35px;
          padding: 0 15px;
          text-transform: none; }
          .Profile-container .top-container .info-container .text-container .request-button.sm-button {
            width: 110px;
            min-width: 110px; }
          .Profile-container .top-container .info-container .text-container .request-button.sm-md-button {
            width: 150px;
            min-width: 150px; }
          .Profile-container .top-container .info-container .text-container .request-button.md-button {
            width: 190px;
            min-width: 190px; }
          .Profile-container .top-container .info-container .text-container .request-button .drop-icon {
            margin-right: -8px; }
    .Profile-container .top-container .whitebar {
      background-color: #fff; }
      .Profile-container .top-container .whitebar .tabs {
        padding: 0 30px;
        max-width: 1100px;
        margin: auto; }
        .Profile-container .top-container .whitebar .tabs .tabs-container {
          display: flex;
          padding-left: 180px; }
          .Profile-container .top-container .whitebar .tabs .tabs-container .tabs-wrapper {
            border-right: 3px solid #f2f3f3;
            margin-right: auto; }
            .Profile-container .top-container .whitebar .tabs .tabs-container .tabs-wrapper .tab {
              min-width: 120px;
              border-left: 3px solid #f2f3f3;
              text-transform: none; }
              .Profile-container .top-container .whitebar .tabs .tabs-container .tabs-wrapper .tab.active {
                background-color: #f2f3f3; }
  .Profile-container .accept-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    margin: 0 auto 8px;
    padding: 0 8px; }
    @media (min-width: 768px) {
      .Profile-container .accept-container {
        display: none; } }
    .Profile-container .accept-container .top {
      margin: 0;
      text-align: center;
      padding: 3px 0 8px; }
    .Profile-container .accept-container .bot {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .Profile-container .accept-container .bot .response-button {
        width: 45%;
        min-height: 33px;
        max-height: 33px;
        padding: 0 30px;
        text-transform: none; }
  .Profile-container .deals-list {
    margin: 0 0 80px;
    padding: 0 30px; }
    .Profile-container .deals-list .empty-text {
      display: flex;
      width: 100%; }
      .Profile-container .deals-list .empty-text .text {
        margin: auto;
        opacity: 0.6;
        margin-top: 100px; }
        @media (max-width: 424px) {
          .Profile-container .deals-list .empty-text .text {
            font-size: 1.5em;
            margin-top: 40px; } }
    @media (max-width: 767px) {
      .Profile-container .deals-list {
        padding: 0; } }
  .Profile-container .profile-wrapper {
    max-width: 1100px;
    width: 100%;
    margin: auto; }
    .Profile-container .profile-wrapper .info-wrapper {
      display: flex;
      justify-content: space-between;
      padding-right: 30px;
      padding-left: 30px; }
      .Profile-container .profile-wrapper .info-wrapper .side {
        min-width: 250px;
        flex-basis: 35%;
        min-width: 344px; }
        .Profile-container .profile-wrapper .info-wrapper .side .contact .contact-item {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px; }
          .Profile-container .profile-wrapper .info-wrapper .side .contact .contact-item .phone-wrapper {
            display: flex;
            align-items: center; }
          .Profile-container .profile-wrapper .info-wrapper .side .contact .contact-item .contact-text {
            flex: 1;
            margin-left: 20px;
            word-break: break-all; }
          .Profile-container .profile-wrapper .info-wrapper .side .contact .contact-item .icon {
            margin-right: 8px; }
      .Profile-container .profile-wrapper .info-wrapper .main {
        flex-basis: 65%; }
        .Profile-container .profile-wrapper .info-wrapper .main.full {
          flex-basis: 100%; }
      .Profile-container .profile-wrapper .info-wrapper .food-chips {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        color: #51d79a; }
        .Profile-container .profile-wrapper .info-wrapper .food-chips .chip {
          margin: 0 4px;
          margin-bottom: 8px; }
          .Profile-container .profile-wrapper .info-wrapper .food-chips .chip.text-chip {
            background: #51d79a;
            padding: 3px 10px;
            border-radius: 4px; }
            .Profile-container .profile-wrapper .info-wrapper .food-chips .chip.text-chip .text {
              color: #fff; }
      .Profile-container .profile-wrapper .info-wrapper .checkbox-grid-container {
        position: relative; }
        .Profile-container .profile-wrapper .info-wrapper .checkbox-grid-container .label {
          width: 80%;
          min-width: 90px;
          padding-bottom: 10px;
          margin-right: 0; }
        @media (min-width: 0) and (max-width: 600px) {
          .Profile-container .profile-wrapper .info-wrapper .checkbox-grid-container {
            width: 85%;
            min-width: 270px; } }
        @media (min-width: 0) and (max-width: 400px) {
          .Profile-container .profile-wrapper .info-wrapper .checkbox-grid-container {
            width: 100%; }
            .Profile-container .profile-wrapper .info-wrapper .checkbox-grid-container .label {
              width: 85%; } }
      .Profile-container .profile-wrapper .info-wrapper .contact, .Profile-container .profile-wrapper .info-wrapper .description, .Profile-container .profile-wrapper .info-wrapper .delivery, .Profile-container .profile-wrapper .info-wrapper .certification {
        background-color: #fff;
        margin: 10px;
        padding: 10px;
        padding-top: 5px;
        border-radius: 10px; }
      .Profile-container .profile-wrapper .info-wrapper .delivery-header, .Profile-container .profile-wrapper .info-wrapper .description-header, .Profile-container .profile-wrapper .info-wrapper .contact-header, .Profile-container .profile-wrapper .info-wrapper .certification-header {
        display: flex;
        align-items: center;
        color: #51d79a;
        border-bottom: 1px solid #51d79a;
        margin: 10px 0 7px;
        padding-left: 10px;
        padding-bottom: 8px; }
        .Profile-container .profile-wrapper .info-wrapper .delivery-header .icon, .Profile-container .profile-wrapper .info-wrapper .description-header .icon, .Profile-container .profile-wrapper .info-wrapper .contact-header .icon, .Profile-container .profile-wrapper .info-wrapper .certification-header .icon {
          margin-right: 10px; }
      .Profile-container .profile-wrapper .info-wrapper .certification-header .add-cert-button {
        margin-left: auto;
        max-height: 33px;
        min-height: 33px;
        padding: 0;
        padding-right: 8px;
        padding-left: 4px;
        text-transform: none; }
      .Profile-container .profile-wrapper .info-wrapper .description {
        min-height: 200px; }
  @media (min-width: 0) and (max-width: 940px) {
    .Profile-container .profile-wrapper {
      margin: 0 auto; }
      .Profile-container .profile-wrapper .info-wrapper {
        flex-direction: column;
        width: 100%;
        min-width: 500px;
        max-width: 600px;
        margin: auto; }
        .Profile-container .profile-wrapper .info-wrapper .side {
          margin: auto;
          width: 100%; }
          .Profile-container .profile-wrapper .info-wrapper .side .contact .contact-body {
            display: flex;
            flex-direction: column; }
            .Profile-container .profile-wrapper .info-wrapper .side .contact .contact-body .contact-item {
              justify-content: space-between; }
              .Profile-container .profile-wrapper .info-wrapper .side .contact .contact-body .contact-item .contact-text {
                margin-left: 0;
                text-align: right; }
        .Profile-container .profile-wrapper .info-wrapper .main {
          margin: auto;
          width: 100%; }
          .Profile-container .profile-wrapper .info-wrapper .main .delivery {
            margin-top: 0; } }
  @media (max-width: 767px) {
    .Profile-container .top-container .backdrop {
      height: 8rem; }
    .Profile-container .top-container .info-container {
      flex-direction: column; }
      .Profile-container .top-container .info-container .avatar {
        margin: auto;
        top: 40px; }
        .Profile-container .top-container .info-container .avatar img {
          border: 8px solid #fff;
          border-radius: 100%;
          width: 140px;
          height: 140px; }
    .Profile-container .top-container .whitebar {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 40px; }
      .Profile-container .top-container .whitebar.extra-padding {
        padding-top: 75px; }
      .Profile-container .top-container .whitebar .text-container {
        z-index: 1;
        display: flex;
        margin: 0 auto 8px;
        flex-direction: column;
        justify-content: center; }
        .Profile-container .top-container .whitebar .text-container .business-name {
          text-align: center;
          margin: auto;
          font-size: 1.8em;
          font-family: "MuseoSans700";
          margin-top: 10px;
          padding: 0; }
        .Profile-container .top-container .whitebar .text-container .request-button-container {
          display: flex;
          flex-direction: column; }
        .Profile-container .top-container .whitebar .text-container .request-button {
          margin: 5px auto 8px;
          min-height: 33px;
          max-height: 33px;
          padding: 0 30px;
          text-transform: none; }
          .Profile-container .top-container .whitebar .text-container .request-button .drop-icon {
            margin-right: -16px; }
          .Profile-container .top-container .whitebar .text-container .request-button.mobil-fixed-width {
            width: 168px; }
        .Profile-container .top-container .whitebar .text-container .accept-button {
          width: 100%;
          margin: 5px auto 8px;
          min-height: 33px;
          max-height: 33px;
          padding: 0 30px;
          text-transform: none; }
      .Profile-container .top-container .whitebar .tabs {
        padding: 0;
        margin: 0;
        width: 100%;
        border-top: 2px solid #f2f3f3; }
        .Profile-container .top-container .whitebar .tabs .tabs-container {
          padding: 0; }
          .Profile-container .top-container .whitebar .tabs .tabs-container .tabs-wrapper {
            margin-top: auto;
            display: block;
            margin-right: 0;
            margin: auto;
            width: 100%; }
            .Profile-container .top-container .whitebar .tabs .tabs-container .tabs-wrapper .tab {
              max-width: none; }
    .Profile-container .mobil-ft {
      display: block; }
    .Profile-container .web-ft {
      display: none !important; } }
  @media (min-width: 0) and (max-width: 500px) {
    .Profile-container .profile-wrapper {
      margin: 0; }
      .Profile-container .profile-wrapper .info-wrapper {
        flex-direction: column;
        padding: 0;
        max-width: 9999px;
        width: auto;
        min-width: 0; }
        .Profile-container .profile-wrapper .info-wrapper .side {
          margin: auto;
          width: 100%;
          min-width: 0; }
          .Profile-container .profile-wrapper .info-wrapper .side .contact .contact-body {
            display: flex;
            flex-direction: column; }
        .Profile-container .profile-wrapper .info-wrapper .main {
          margin: auto;
          width: 100%; } }

.profile-dropdown.sm-popper {
  width: 110px;
  min-width: 110px; }

.profile-dropdown.md-popper {
  width: 190px;
  min-width: 190px; }

.profile-dropdown ul {
  padding-bottom: 0; }

.profile-dropdown li {
  justify-content: center;
  font-size: 15px; }
  .profile-dropdown li:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.164); }

@media (max-width: 767px) {
  .profile-dropdown {
    left: 2px !important;
    width: 100% !important; }
    .profile-dropdown li:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.164); } }

.help-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin-left: 250px;
  margin-top: 112px;
  margin-bottom: 120px; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .help-container {
      margin-left: 50px; } }
  @media (min-width: 0) and (max-width: 549px) {
    .help-container {
      margin-left: 0; } }
  @media (min-width: 0) and (max-width: 549px) {
    .help-container {
      margin-top: 56px; } }
  .help-container .help-wrapper {
    max-width: 1100px;
    width: 100%;
    margin: auto;
    padding-top: 30px;
    text-align: center; }
    .help-container .help-wrapper .heading {
      margin: auto;
      width: 66%;
      min-width: 300px;
      max-width: 600px;
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-size: 2em; }
    .help-container .help-wrapper .grid-item {
      margin: auto;
      width: 66%;
      min-width: 300px;
      max-width: 600px;
      margin-bottom: 20px;
      text-align: center; }
      .help-container .help-wrapper .grid-item .info-container {
        padding: 10px;
        box-shadow: none;
        border-radius: 10px; }
        .help-container .help-wrapper .grid-item .info-container .subheading {
          border-bottom: 1px solid rgba(0, 0, 0, 0.185); }
          .help-container .help-wrapper .grid-item .info-container .subheading h2 {
            font-size: 1.6em; }
        .help-container .help-wrapper .grid-item .info-container .info {
          min-height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: none;
          padding: 5px; }

.legal-container {
  width: 100%; }
  .legal-container .header-container {
    display: flex;
    background-image: url("./../assets/Cover.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    width: 100%;
    overflow-y: auto; }
    .legal-container .header-container .text {
      margin: auto;
      font-size: 5.5em;
      font-family: "MuseoSans700"; }
  .legal-container .info-container {
    display: flex; }
    .legal-container .info-container .hide {
      display: none; }
    .legal-container .info-container .legal-content {
      margin: 0 auto 80px;
      padding: 40px 15px 80px;
      width: 100%;
      max-width: 800px;
      background: #fff; }
  @media (max-width: 767px) {
    .legal-container .header-container {
      height: 180px; }
      .legal-container .header-container .text {
        font-size: 4em; } }
  @media (max-width: 424px) {
    .legal-container .header-container {
      height: 140px; }
      .legal-container .header-container .text {
        font-size: 2.6em; } }

.card-wrapper {
  margin: 0 10px 8px; }
  .card-wrapper .card-container {
    background: #fff;
    border-radius: 10px; }
  .card-wrapper .card-cart {
    display: flex;
    align-items: center;
    margin: 0 auto 0 auto;
    margin-left: auto;
    padding: 0;
    border-radius: 10px !important; }
    .card-wrapper .card-cart .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 107px;
      min-width: 107px;
      margin: auto 8px; }
      .card-wrapper .card-cart .image-container .image-floater {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90%;
        min-width: 90%;
        overflow: hidden;
        border-radius: 10px; }
        .card-wrapper .card-cart .image-container .image-floater .card-image {
          max-width: 10%;
          max-height: 10%;
          transform: scale(10); }
    .card-wrapper .card-cart .info-section {
      display: flex;
      flex-grow: 1;
      margin-top: 5px; }
      .card-wrapper .card-cart .info-section .major-text {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        padding: 0 0 0 10px;
        margin: 15px 0; }
        .card-wrapper .card-cart .info-section .major-text .card-name-container {
          padding: 0 10px;
          flex-grow: 1; }
          .card-wrapper .card-cart .info-section .major-text .card-name-container .name {
            margin-bottom: auto;
            font-family: "MuseoSans700"; }
          .card-wrapper .card-cart .info-section .major-text .card-name-container .label {
            font-size: 1.1em;
            line-height: 16px !important;
            text-decoration: underline;
            margin-bottom: 10px; }
          @media (min-width: 768px) and (max-width: 820px) {
            .card-wrapper .card-cart .info-section .major-text .card-name-container .name {
              font-size: 1.0em; }
            .card-wrapper .card-cart .info-section .major-text .card-name-container .label {
              font-size: 1.1em; } }
        .card-wrapper .card-cart .info-section .major-text .card-info-container {
          display: flex;
          flex-direction: column;
          border-right: 1px solid #ddd;
          border-left: 1px solid #ddd;
          padding: 0 10px;
          min-width: 200px; }
          .card-wrapper .card-cart .info-section .major-text .card-info-container .subinfo {
            display: flex;
            flex-wrap: nowrap; }
            .card-wrapper .card-cart .info-section .major-text .card-info-container .subinfo .value {
              margin-left: auto; }
        .card-wrapper .card-cart .info-section .major-text .sm {
          font-size: 1em;
          font-family: "MuseoSans300"; }
        .card-wrapper .card-cart .info-section .major-text .lg {
          font-size: 1.4em; }
      .card-wrapper .card-cart .info-section .minor-text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-right: none;
        padding: 0 10px;
        margin: 15px 0;
        min-width: 97px; }
        .card-wrapper .card-cart .info-section .minor-text .md {
          font-size: 1.4em; }
        .card-wrapper .card-cart .info-section .minor-text .price-container {
          display: flex;
          flex-direction: row;
          margin-right: 10px; }
          .card-wrapper .card-cart .info-section .minor-text .price-container .price {
            font-family: "MuseoSans500"; }
          .card-wrapper .card-cart .info-section .minor-text .price-container .label {
            margin-left: 3px;
            font-family: "MuseoSans300"; }
        .card-wrapper .card-cart .info-section .minor-text .detail-container {
          display: flex;
          flex-direction: row; }
          .card-wrapper .card-cart .info-section .minor-text .detail-container .label {
            font-family: "MuseoSans300";
            font-size: 1.1em;
            color: #d75751; }
            .card-wrapper .card-cart .info-section .minor-text .detail-container .label.divider {
              margin-left: 0; }
            .card-wrapper .card-cart .info-section .minor-text .detail-container .label.hidden {
              display: none; }
      .card-wrapper .card-cart .info-section .card-actions {
        margin-left: auto;
        padding: 0 8px; }
        .card-wrapper .card-cart .info-section .card-actions .details-btn {
          padding: 0;
          min-height: 30px;
          min-width: 75px;
          background-color: #51d79a;
          text-transform: none; }
          .card-wrapper .card-cart .info-section .card-actions .details-btn .MuiButton-label-2 {
            font-size: 0.9em; }
    .card-wrapper .card-cart .mobil-ft {
      display: none !important; }
    @media (max-width: 767px) {
      .card-wrapper .card-cart {
        cursor: pointer; }
        .card-wrapper .card-cart .image-container {
          margin: 10px auto auto;
          height: 100px;
          min-width: 100px;
          border-radius: 0; }
          .card-wrapper .card-cart .image-container .image-floater {
            height: 90%;
            min-width: 90%; }
            .card-wrapper .card-cart .image-container .image-floater .card-image {
              max-width: 10%;
              max-height: 10%;
              transform: scale(10); }
        .card-wrapper .card-cart .info-section {
          flex-wrap: wrap;
          margin-right: 10px;
          margin-left: 10px; }
          .card-wrapper .card-cart .info-section .major-text {
            width: 100%;
            padding: 0;
            flex-direction: column;
            border-style: none;
            margin: 0;
            margin-top: 10px; }
            .card-wrapper .card-cart .info-section .major-text .card-name-container {
              display: block;
              border-style: none;
              padding: 0;
              margin-left: 10px; }
              .card-wrapper .card-cart .info-section .major-text .card-name-container .name {
                margin-bottom: 4px; }
              .card-wrapper .card-cart .info-section .major-text .card-name-container .label {
                font-size: 1em;
                margin-bottom: 1px;
                text-decoration: none; }
            .card-wrapper .card-cart .info-section .major-text .card-info-container {
              flex-direction: column;
              justify-content: space-between;
              max-width: 270px;
              border-style: none; }
              .card-wrapper .card-cart .info-section .major-text .card-info-container .subinfo {
                display: flex;
                flex-wrap: nowrap;
                min-width: 70px; }
                .card-wrapper .card-cart .info-section .major-text .card-info-container .subinfo .value {
                  margin-left: 1.2rem; }
            .card-wrapper .card-cart .info-section .major-text .lg {
              font-size: 1.3em; }
            .card-wrapper .card-cart .info-section .major-text .sm {
              font-size: 1em; }
          .card-wrapper .card-cart .info-section .minor-text {
            display: flex;
            flex-direction: row-reverse;
            flex-grow: 1;
            justify-content: space-between;
            padding: 0;
            border-style: none;
            margin: 0; }
            .card-wrapper .card-cart .info-section .minor-text .md {
              font-size: 1.2em; }
            .card-wrapper .card-cart .info-section .minor-text .price-container .price {
              font-family: "MuseoSans500"; }
            .card-wrapper .card-cart .info-section .minor-text .price-container .label {
              margin-left: 3px;
              font-family: "MuseoSans300"; }
            .card-wrapper .card-cart .info-section .minor-text .detail-container .label {
              font-size: 0.9em; }
            .card-wrapper .card-cart .info-section .minor-text .label {
              margin-left: 8px; }
          .card-wrapper .card-cart .info-section .card-actions {
            margin-left: 0;
            margin-left: auto;
            padding: 0; }
            .card-wrapper .card-cart .info-section .card-actions .details-btn {
              min-height: 30px;
              min-width: 70px;
              margin: 6px 0; }
              .card-wrapper .card-cart .info-section .card-actions .details-btn .MuiButton-label-2 {
                font-size: 0.85em; }
          .card-wrapper .card-cart .info-section .subinfo {
            font-size: 0.9em; } }
    @media (max-width: 767px) and (min-width: 0) and (max-width: 462px) {
      .card-wrapper .card-cart .info-section {
        margin-left: 0; }
        .card-wrapper .card-cart .info-section .major-text .lg {
          font-size: 1.1em; } }
    @media (max-width: 767px) {
        .card-wrapper .card-cart .web-ft {
          display: none !important; }
        .card-wrapper .card-cart .mobil-ft {
          display: block; } }
    @media (max-width: 424px) {
      .card-wrapper .card-cart .image-container {
        margin: 10px auto auto;
        border-radius: 0; }
        .card-wrapper .card-cart .image-container .image-floater .card-image {
          max-width: 10%;
          max-height: 10%;
          transform: scale(10); }
      .card-wrapper .card-cart .info-section .major-text .card-name-container {
        padding-left: 0px;
        margin-left: 4px; }
        .card-wrapper .card-cart .info-section .major-text .card-name-container .name {
          margin-bottom: 2px; }
      .card-wrapper .card-cart .info-section .major-text .card-info-container {
        max-width: 200px;
        padding-left: 4px; }
        .card-wrapper .card-cart .info-section .major-text .card-info-container .subinfo {
          min-width: 80px; }
          .card-wrapper .card-cart .info-section .major-text .card-info-container .subinfo .label,
          .card-wrapper .card-cart .info-section .major-text .card-info-container .subinfo .value {
            font-size: 0.85em; }
          .card-wrapper .card-cart .info-section .major-text .card-info-container .subinfo .value {
            margin-left: 8px; }
      .card-wrapper .card-cart .info-section .minor-text .price,
      .card-wrapper .card-cart .info-section .minor-text .label {
        font-size: 1.1em; }
      .card-wrapper .card-cart .info-section .minor-text .label {
        margin-right: 4px; }
      .card-wrapper .card-cart .info-section .card-actions .details-btn {
        min-height: 25px;
        min-width: 70px; }
        .card-wrapper .card-cart .info-section .card-actions .details-btn .MuiButton-label-2 {
          font-size: 0.7em; } }
    .card-wrapper .card-cart .label {
      color: #51d79a; }
  @media (max-width: 424px) {
    .card-wrapper {
      margin: 0 0 8px; }
      .card-wrapper .card-container {
        background: #fff;
        border-radius: 0; } }

.basket-header {
  font-family: 'MuseoSans300', 'sans-serif';
  font-size: 2rem;
  color: #4c4c4c;
  margin-bottom: 1rem; }
  @media screen and (min-width: 550px) {
    .basket-header {
      margin-left: 7.5%; } }
  @media screen and (max-width: 549px) {
    .basket-header {
      display: flex;
      justify-content: center;
      align-items: center; } }

.btn-basic {
  position: absolute;
  transform: translate(-50%, -130%);
  top: 100%;
  left: 3.5rem; }
  .btn-basic button {
    -webkit-appearance: none;
    padding: 0 0.8rem;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    height: 1.5rem;
    background-color: #ef594f;
    width: 5rem;
    color: white;
    border: none;
    outline: none;
    font-size: 1rem;
    cursor: pointer; }

.basket-total-purchase {
  font-family: 'MuseoSans300', 'sans-serif';
  font-size: 1.2rem;
  display: flex;
  font-weight: bold;
  justify-content: center;
  color: #4c4c4c; }

.baskets-container {
  font-family: 'MuseoSans300', 'sans-serif';
  background-size: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 230px;
  width: 100%;
  margin-left: 250px;
  margin-top: 112px; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .baskets-container {
      margin-left: 50px; } }
  @media (min-width: 0) and (max-width: 549px) {
    .baskets-container {
      margin-left: 0; } }
  @media (min-width: 0) and (max-width: 549px) {
    .baskets-container {
      margin-top: 56px;
      margin-bottom: 100px; } }
  .baskets-container .btn-primary {
    border: none;
    background: none; }
  .baskets-container .empty-text {
    display: flex;
    margin: auto; }
    .baskets-container .empty-text .text {
      opacity: 0.6;
      margin-top: -100px; }
      @media (max-width: 424px) {
        .baskets-container .empty-text .text {
          font-size: 1.5em;
          margin-top: -40px; } }

.button-submit {
  position: relative;
  margin: 1rem 0 0rem 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .button-submit button {
    background-color: #3DD28F;
    color: #fff;
    text-transform: none; }

.baskets-list {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 30px;
  width: 100%;
  margin-top: 1%;
  flex-wrap: wrap; }
  @media (min-width: 0) and (max-width: 549px) {
    .baskets-list {
      background-color: #fff; } }

.modal-request {
  width: 90% !important;
  max-width: 800px !important;
  padding: 0 !important;
  background: rgba(0, 0, 0, 0) !important;
  border-style: none !important; }
  .modal-request .modal-scroll {
    overflow: scroll;
    background: rgba(0, 0, 0, 0);
    display: flex;
    height: 100vh; }
    .modal-request .modal-scroll .item-topbar {
      display: none;
      position: absolute;
      top: 0;
      top: env(safe-area-inset-top);
      width: 100%;
      z-index: 300; }
      .modal-request .modal-scroll .item-topbar .appbar {
        background-color: #fff; }
        .modal-request .modal-scroll .item-topbar .appbar .text {
          padding-left: 0; }
    .modal-request .modal-scroll .bottom-Appbar {
      display: none; }
    .modal-request .modal-scroll::-webkit-scrollbar {
      display: none; }
    .modal-request .modal-scroll .popup {
      position: relative;
      width: 100%;
      margin: auto; }
      .modal-request .modal-scroll .popup .modal-close-btn {
        position: absolute;
        right: 0; }
      .modal-request .modal-scroll .popup .modal-container {
        display: flex;
        flex-wrap: wrap;
        padding: 48px 1rem 1.4rem;
        background-color: #fff;
        border-radius: 20px; }
        .modal-request .modal-scroll .popup .modal-container .deal-display {
          width: 40%;
          padding-top: 20px;
          display: flex;
          flex-direction: column; }
          .modal-request .modal-scroll .popup .modal-container .deal-display .img-crop {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 240px;
            width: 240px;
            margin: 0 auto;
            overflow: hidden; }
            .modal-request .modal-scroll .popup .modal-container .deal-display .img-crop .modal-img {
              max-width: 10%;
              max-height: 10%;
              transform: scale(10); }
          .modal-request .modal-scroll .popup .modal-container .deal-display .modal-title {
            margin: 1rem 1rem 0;
            text-align: center; }
          .modal-request .modal-scroll .popup .modal-container .deal-display .hours {
            margin: 10px auto auto; }
        .modal-request .modal-scroll .popup .modal-container .info {
          display: flex;
          flex-direction: column;
          width: 55%;
          margin-left: auto; }
          .modal-request .modal-scroll .popup .modal-container .info .top-info {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal-request .modal-scroll .popup .modal-container .info .top-info .grid-item {
              display: flex;
              flex-direction: column;
              flex-grow: 1 !important;
              border-right: 1px solid #ddd; }
              .modal-request .modal-scroll .popup .modal-container .info .top-info .grid-item:last-child {
                border-style: none; }
              .modal-request .modal-scroll .popup .modal-container .info .top-info .grid-item .label, .modal-request .modal-scroll .popup .modal-container .info .top-info .grid-item .value {
                margin: auto; }
          .modal-request .modal-scroll .popup .modal-container .info .details {
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal-request .modal-scroll .popup .modal-container .info .details .label {
              margin-bottom: 10px; }
            .modal-request .modal-scroll .popup .modal-container .info .details .grid-item {
              display: flex;
              flex-wrap: nowrap;
              padding: 6px 0; }
              .modal-request .modal-scroll .popup .modal-container .info .details .grid-item .gd {
                margin-left: 16px; }
          .modal-request .modal-scroll .popup .modal-container .info .reason-info {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal-request .modal-scroll .popup .modal-container .info .reason-info .value {
              margin-left: 16px; }
          .modal-request .modal-scroll .popup .modal-container .info .bottom {
            margin-bottom: 10px; }
            .modal-request .modal-scroll .popup .modal-container .info .bottom .label {
              margin-bottom: 10px; }
          .modal-request .modal-scroll .popup .modal-container .info .modal-buttons {
            display: flex;
            margin-left: auto;
            margin-top: auto; }
            .modal-request .modal-scroll .popup .modal-container .info .modal-buttons button {
              width: 85px;
              height: 30px;
              margin-left: 30px;
              text-transform: none; }
        .modal-request .modal-scroll .popup .modal-container .lg {
          font-size: 1.7em;
          font-family: "MuseoSans700"; }
        .modal-request .modal-scroll .popup .modal-container .md {
          font-size: 1.2em;
          font-family: "MuseoSans300"; }
        .modal-request .modal-scroll .popup .modal-container .sm {
          font-size: 1.1em;
          font-family: "MuseoSans300"; }
  @media (max-width: 767px) {
    .modal-request {
      max-width: 500px !important; }
      .modal-request .modal-scroll .popup .modal-container {
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem; }
        .modal-request .modal-scroll .popup .modal-container .deal-display {
          width: 100%;
          padding-top: 0; }
          .modal-request .modal-scroll .popup .modal-container .deal-display .modal-title {
            margin: 1rem 0; }
          .modal-request .modal-scroll .popup .modal-container .deal-display .hours {
            margin: 5px auto; }
        .modal-request .modal-scroll .popup .modal-container .info {
          width: 100%; }
        .modal-request .modal-scroll .popup .modal-container .web-ft {
          display: none; }
        .modal-request .modal-scroll .popup .modal-container .lg {
          font-size: 1.6em; } }
  @media (min-width: 0) and (max-width: 500px) {
    .modal-request {
      width: 100vw !important; }
      .modal-request .modal-scroll {
        padding-bottom: 0; }
        .modal-request .modal-scroll .item-topbar {
          display: block; }
        .modal-request .modal-scroll .bottom-Appbar {
          display: block; }
        .modal-request .modal-scroll .popup {
          padding-bottom: 0;
          margin: 0; }
          .modal-request .modal-scroll .popup .modal-close-btn {
            display: none; }
          .modal-request .modal-scroll .popup .modal-container {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            height: auto;
            margin: 40px 0 0;
            padding-bottom: 112px;
            padding-top: 56px;
            padding-left: 1rem;
            padding-right: 1rem; }
            .modal-request .modal-scroll .popup .modal-container .info .modal-buttons {
              display: none; } }

.card-request {
  display: flex;
  align-items: center;
  margin: 0 auto 0 auto;
  margin-left: auto;
  padding: 0;
  border-radius: 10px !important; }
  .card-request .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    min-width: 70px;
    margin: auto 8px; }
    .card-request .image-container .image-floater {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90%;
      min-width: 90%;
      overflow: hidden;
      border-radius: 10px; }
      .card-request .image-container .image-floater:hover {
        cursor: pointer; }
      .card-request .image-container .image-floater .card-image {
        max-width: 10%;
        max-height: 10%;
        transform: scale(10); }
  .card-request .info-section {
    display: flex;
    flex-grow: 1;
    padding-right: 10px; }
    .card-request .info-section .major-text {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      padding: 0 0 0 10px;
      margin: 15px 0 0; }
      .card-request .info-section .major-text .card-name-container {
        padding: 0 10px;
        margin: 0;
        flex-grow: 1; }
        .card-request .info-section .major-text .card-name-container .name {
          margin-bottom: auto;
          font-family: "MuseoSans700"; }
          .card-request .info-section .major-text .card-name-container .name:hover {
            text-decoration: underline;
            cursor: pointer; }
        .card-request .info-section .major-text .card-name-container .label {
          font-size: 1.1em;
          line-height: 16px !important;
          text-decoration: none;
          margin-bottom: 10px; }
        @media (min-width: 768px) and (max-width: 820px) {
          .card-request .info-section .major-text .card-name-container .name {
            font-size: 1.0em; }
          .card-request .info-section .major-text .card-name-container .label {
            font-size: 1.1em; } }
      .card-request .info-section .major-text .card-info-container {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        padding: 0 10px;
        min-width: 200px; }
        .card-request .info-section .major-text .card-info-container .subinfo {
          display: flex;
          flex-wrap: nowrap; }
          .card-request .info-section .major-text .card-info-container .subinfo .value {
            margin-left: auto; }
      .card-request .info-section .major-text .sm {
        font-size: 1em;
        font-family: "MuseoSans300"; }
      .card-request .info-section .major-text .lg {
        font-size: 1.4em; }
    .card-request .info-section .minor-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-right: none;
      padding: 0 10px;
      margin: 15px 0 0;
      min-width: 97px; }
      .card-request .info-section .minor-text .md {
        font-size: 1.4em; }
      .card-request .info-section .minor-text .price-container {
        display: flex;
        flex-direction: row;
        margin-right: 10px; }
        .card-request .info-section .minor-text .price-container .price {
          font-family: "MuseoSans500"; }
        .card-request .info-section .minor-text .price-container .label {
          margin-left: 3px;
          font-family: "MuseoSans300"; }
      .card-request .info-section .minor-text .detail-container {
        display: flex;
        flex-direction: row; }
        .card-request .info-section .minor-text .detail-container .label {
          font-family: "MuseoSans300";
          color: #d75751;
          font-size: 1.1em; }
          .card-request .info-section .minor-text .detail-container .label.divider {
            margin-left: 0; }
          .card-request .info-section .minor-text .detail-container .label.hidden {
            display: none; }
        .card-request .info-section .minor-text .detail-container .details-button {
          margin-top: 8px;
          min-height: 25px;
          max-height: 25px;
          padding: 0;
          text-transform: none; }
    .card-request .info-section .card-actions {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: auto;
      padding: 0; }
      .card-request .info-section .card-actions .details-btn {
        padding: 0;
        min-height: 30px;
        min-width: 75px;
        background-color: #51d79a; }
        .card-request .info-section .card-actions .details-btn .MuiButton-label-2 {
          font-size: 0.9em; }
  @media (max-width: 767px) {
    .card-request {
      cursor: pointer; }
      .card-request .image-container {
        margin: 0 auto auto;
        height: 90px;
        min-width: 90px;
        border-radius: 0; }
        .card-request .image-container .image-floater {
          height: 90%;
          min-width: 90%; }
          .card-request .image-container .image-floater .card-image {
            max-width: 10%;
            max-height: 10%;
            transform: scale(10); }
      .card-request .info-section {
        flex-wrap: wrap;
        margin-right: 10px; }
        .card-request .info-section .major-text {
          width: 100%;
          padding: 0;
          flex-direction: column;
          border-style: none;
          margin: 0;
          margin-top: 8px; }
          .card-request .info-section .major-text .card-name-container {
            display: block;
            border-style: none;
            padding: 0;
            margin-left: 10px; }
            .card-request .info-section .major-text .card-name-container .name {
              margin-bottom: 4px; }
            .card-request .info-section .major-text .card-name-container .label {
              font-size: 1em;
              margin-bottom: 0;
              text-decoration: none; }
          .card-request .info-section .major-text .card-info-container {
            flex-direction: column;
            justify-content: space-between;
            max-width: 270px;
            border-style: none; }
            .card-request .info-section .major-text .card-info-container .subinfo {
              display: flex;
              flex-wrap: nowrap;
              min-width: 100px; }
              .card-request .info-section .major-text .card-info-container .subinfo .value {
                margin-left: 8px; }
          .card-request .info-section .major-text .lg {
            font-size: 1.3em; }
          .card-request .info-section .major-text .sm {
            font-size: 1em; }
        .card-request .info-section .minor-text {
          display: flex;
          flex-direction: row-reverse;
          flex-grow: 1;
          justify-content: space-between;
          padding: 0;
          border-style: none;
          padding-top: 5px;
          margin: 0; }
          .card-request .info-section .minor-text .md {
            font-size: 1.2em; }
          .card-request .info-section .minor-text .price-container {
            padding-right: 5%; }
            .card-request .info-section .minor-text .price-container .price {
              font-family: "MuseoSans500"; }
            .card-request .info-section .minor-text .price-container .label {
              margin-left: 3px;
              font-family: "MuseoSans300"; }
          .card-request .info-section .minor-text .detail-container .label {
            font-size: 0.9em; }
          .card-request .info-section .minor-text .detail-container .details-button {
            margin-top: 0;
            margin-left: 10px; }
          .card-request .info-section .minor-text .label {
            margin-left: 8px; }
        .card-request .info-section .card-actions {
          margin-left: 0;
          margin-left: auto;
          padding: 0; }
          .card-request .info-section .card-actions .details-btn {
            min-height: 30px;
            min-width: 70px;
            margin: 6px 0; }
            .card-request .info-section .card-actions .details-btn .MuiButton-label-2 {
              font-size: 0.85em; }
        .card-request .info-section .subinfo {
          font-size: 0.9em; } }
    @media (max-width: 767px) and (min-width: 0) and (max-width: 462px) {
      .card-request .info-section {
        margin-left: 0; }
        .card-request .info-section .major-text .lg {
          font-size: 1.1em; } }
  @media (max-width: 767px) {
      .card-request .web-ft {
        display: none !important; }
      .card-request .mobil-ft {
        display: block; } }
  @media (max-width: 424px) {
    .card-request .image-container {
      height: 70px;
      min-width: 70px;
      border-radius: 0; }
      .card-request .image-container .image-floater .card-image {
        max-width: 10%;
        max-height: 10%;
        transform: scale(10); }
    .card-request .info-section .major-text .card-name-container {
      padding-left: 0px;
      margin-left: 4px; }
      .card-request .info-section .major-text .card-name-container .name {
        margin-bottom: 2px; }
    .card-request .info-section .major-text .card-info-container {
      max-width: 200px;
      padding-left: 4px; }
      .card-request .info-section .major-text .card-info-container .subinfo {
        min-width: 80px; }
        .card-request .info-section .major-text .card-info-container .subinfo .label,
        .card-request .info-section .major-text .card-info-container .subinfo .value {
          font-size: 0.85em; }
        .card-request .info-section .major-text .card-info-container .subinfo .value {
          margin-left: 8px; }
    .card-request .info-section .minor-text .price,
    .card-request .info-section .minor-text .label {
      font-size: 1.1em; }
    .card-request .info-section .minor-text .label {
      margin-right: 4px; }
    .card-request .info-section .minor-text .detail-container .details-button {
      margin-left: 0; }
    .card-request .info-section .card-actions .details-btn {
      min-height: 25px;
      min-width: 70px; }
      .card-request .info-section .card-actions .details-btn .MuiButton-label-2 {
        font-size: 0.7em; } }
  .card-request .label {
    color: #51d79a; }

.card-author {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 3px !important;
  border-radius: 10px !important; }
  .card-author div:first-child {
    margin: 2px 0 !important;
    flex-direction: column; }
  .card-author .text {
    display: flex;
    flex-direction: row;
    padding-left: 10px; }
  .card-author .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    min-width: 75px;
    margin: auto 8px; }
    .card-author .image-container .image-floater {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90%;
      min-width: 90%;
      overflow: hidden;
      border-radius: 10px; }
      .card-author .image-container .image-floater .card-image {
        max-width: 10%;
        max-height: 10%;
        transform: scale(10); }
  .card-author .contact-container {
    display: flex;
    flex-direction: column; }
    .card-author .contact-container .contact-button {
      margin: auto; }
      .card-author .contact-container .contact-button .action-button {
        text-transform: none;
        min-height: 30px;
        max-height: 30px;
        padding: 0; }
  .card-author .info-section {
    display: flex;
    flex-grow: 1;
    margin-top: 5px; }
    .card-author .info-section .major-text {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      padding-left: 10px; }
      .card-author .info-section .major-text .card-name-container {
        display: flex;
        flex-direction: row; }
        .card-author .info-section .major-text .card-name-container .name {
          margin-bottom: auto;
          font-family: "MuseoSans700"; }
          .card-author .info-section .major-text .card-name-container .name.profile-link .text_icon {
            font-size: 13px;
            margin-bottom: 4px;
            color: #51d79a; }
          .card-author .info-section .major-text .card-name-container .name.profile-link:hover {
            text-decoration: underline;
            cursor: pointer; }
        .card-author .info-section .major-text .card-name-container .label {
          font-size: 1.1em;
          line-height: 16px !important;
          text-decoration: none;
          margin-bottom: 10px; }
        .card-author .info-section .major-text .card-name-container .filler {
          flex-grow: 1; }
      .card-author .info-section .major-text .card-info-container {
        display: flex;
        flex-direction: column; }
        .card-author .info-section .major-text .card-info-container .subinfo {
          display: flex;
          flex-wrap: nowrap; }
          .card-author .info-section .major-text .card-info-container .subinfo .value {
            margin-left: 1.6rem; }
      .card-author .info-section .major-text .card-label-container {
        display: flex;
        flex-wrap: wrap;
        width: 80%; }
        .card-author .info-section .major-text .card-label-container .chip {
          height: 25px;
          font-size: 1em;
          margin-bottom: 4px;
          margin-right: 8px !important;
          border-radius: 6px; }
          .card-author .info-section .major-text .card-label-container .chip.null {
            background-color: #aaa;
            color: #fff; }
          .card-author .info-section .major-text .card-label-container .chip.positive {
            background-color: #51d79a;
            color: #fff; }
          .card-author .info-section .major-text .card-label-container .chip.negative {
            background-color: #d75751;
            color: #fff; }
  .card-author .mobil-ft {
    display: none !important; }
  @media (max-width: 767px) {
    .card-author {
      cursor: pointer; }
      .card-author .card-label-container .chip {
        height: 20px;
        font-size: 0.7em; }
      .card-author .image-container {
        margin: auto;
        height: 90px;
        min-width: 90px;
        border-radius: 0; }
        .card-author .image-container .image-floater {
          height: 90%;
          min-width: 90%; }
          .card-author .image-container .image-floater .card-image {
            max-width: 10%;
            max-height: 10%;
            transform: scale(10); }
      .card-author .info-section {
        flex-wrap: wrap;
        margin-right: 10px;
        margin-left: 10px; }
        .card-author .info-section .major-text .card-label-container .chip {
          height: 20px;
          font-size: 0.7em; }
        .card-author .info-section .subinfo {
          font-size: 0.9em; } }
    @media (max-width: 767px) and (min-width: 0) and (max-width: 462px) {
      .card-author .info-section {
        margin-left: 0; }
        .card-author .info-section .major-text .lg {
          font-size: 1.1em; } }
  @media (max-width: 424px) {
    .card-author .image-container {
      margin: auto;
      height: 40px;
      flex-grow: 1;
      min-width: 40px;
      border-radius: 0; }
      .card-author .image-container .image-floater .card-image {
        max-width: 10%;
        max-height: 10%;
        transform: scale(10); }
    .card-author .info-section .major-text .card-name-container {
      padding-left: 0px;
      margin-left: 4px; }
      .card-author .info-section .major-text .card-name-container .name {
        margin-bottom: 2px; }
    .card-author .info-section .major-text .card-info-container {
      max-width: 200px;
      padding-left: 0; }
      .card-author .info-section .major-text .card-info-container .subinfo {
        min-width: 80px; }
        .card-author .info-section .major-text .card-info-container .subinfo .value {
          margin-left: 8px; }
        .card-author .info-section .major-text .card-info-container .subinfo .label, .card-author .info-section .major-text .card-info-container .subinfo .value {
          font-size: 1em; }
    .card-author .info-section .major-text .card-label-container {
      padding-left: 0px; } }
  .card-author .label {
    color: #51d79a; }

.request-container {
  font-family: 'MuseoSans300', 'sans-serif';
  display: flex;
  flex-direction: column;
  background-size: auto;
  width: 100%;
  margin-left: 250px;
  margin-top: 112px; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .request-container {
      margin-left: 50px; } }
  @media (min-width: 0) and (max-width: 549px) {
    .request-container {
      margin-left: 0; } }
  @media (min-width: 0) and (max-width: 549px) {
    .request-container {
      margin-top: 104px;
      margin-bottom: 60px; } }
  .request-container > div {
    text-align: center;
    margin-top: auto;
    margin-bottom: 40px; }

.request-list {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 60px;
  width: 100%;
  margin-top: 1%;
  flex-wrap: wrap; }
  .request-list .empty-text {
    display: flex;
    width: 100%; }
    .request-list .empty-text .text {
      margin: auto;
      opacity: 0.6;
      margin-top: 100px; }
      @media (max-width: 424px) {
        .request-list .empty-text .text {
          font-size: 1.5em;
          margin-top: 40px; } }
  .request-list .panel {
    box-shadow: none; }
    .request-list .panel .table-summary {
      margin: 0;
      border-bottom: 3px solid #51d79a; }
    .request-list .panel .panel-buttons {
      background-color: rgba(61, 210, 143, 0.123);
      padding-top: 5px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column; }
      .request-list .panel .panel-buttons .pagination {
        margin-top: 15px; }
      .request-list .panel .panel-buttons .buttons-container {
        display: flex;
        justify-content: flex-end;
        margin-left: auto; }
        .request-list .panel .panel-buttons .buttons-container .button {
          margin-left: 8px;
          padding-top: 5px;
          text-transform: none; }
  .request-list .mobil-ft {
    display: none; }
  @media (max-width: 767px) {
    .request-list .mobil-ft {
      display: flex; } }

.order-product-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0; }
  .order-product-list .colored-bg {
    background-color: rgba(61, 210, 143, 0.123); }
  .order-product-list .request-label {
    background: #51d79a; }
    .order-product-list .request-label .text {
      color: #fff;
      text-align: center;
      font-size: 1.3em; }
  .order-product-list .panel-wrapper {
    margin: 0 10px 8px; }
    .order-product-list .panel-wrapper .panel-container {
      background: #fff;
      border-radius: 10px;
      overflow: hidden; }
  .order-product-list .colored-text {
    color: #51d79a; }
    .order-product-list .colored-text.spaced {
      margin-right: auto; }
  @media (max-width: 424px) {
    .order-product-list .md {
      font-size: 1.1em; } }
  @media (max-width: 767px) {
    .order-product-list .request-label {
      background: #51d79a; }
      .order-product-list .request-label .text {
        color: #fff;
        text-align: center;
        font-size: 1.1em; } }
  @media (max-width: 424px) {
    .order-product-list .panel-wrapper {
      margin: 0 0 8px; }
      .order-product-list .panel-wrapper .panel-container {
        background: #fff;
        border-radius: 0; } }

.SignUp-container {
  font-family: 'MuseoSans300', 'sans-serif';
  background-image: url("./../assets/Cover.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  position: fixed;
  display: table-cell;
  object-fit: contain;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: auto; }
  .SignUp-container .SignUp-context {
    display: flex;
    justify-content: center;
    overflow-y: hidden; }
    .SignUp-container .SignUp-context input {
      background: transparent;
      border: none;
      width: 215%;
      border-bottom: 3px solid #3DD28F;
      color: #808080;
      font-size: 1rem;
      position: relative;
      top: -3em; }
    .SignUp-container .SignUp-context label {
      width: 9em;
      margin-right: 9.5em;
      padding-top: 1.75em;
      color: #808080; }
  @media screen and (max-width: 549px) {
    .SignUp-container .sign-up-wrapper {
      background-color: rgba(255, 255, 255, 0.65);
      font-size: 1.2em;
      margin-top: 0rem;
      margin-bottom: 1.5rem;
      margin-left: auto;
      margin-right: auto;
      border: none;
      width: 100%;
      height: 57rem; } }
  @media screen and (min-width: 550px) {
    .SignUp-container .sign-up-wrapper {
      background-color: rgba(255, 255, 255, 0.65);
      border-radius: 70px;
      margin-top: 1.5rem;
      margin-bottom: 1.5em;
      margin-left: auto;
      margin-right: auto;
      border: none;
      width: 50%;
      height: 40em; } }
  .SignUp-container .btn-submit-sign {
    margin: 1rem 0 0rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em; }
    .SignUp-container .btn-submit-sign button {
      padding: 0 0.8rem;
      margin-right: 0.5rem;
      border-radius: 0.5rem;
      height: 2.5rem;
      box-shadow: 0 0.3rem 1rem #e8e8e8;
      background-color: #3DD28F;
      width: 90px;
      color: white;
      border: none;
      outline: none;
      font-size: 1rem;
      cursor: pointer; }
  .SignUp-container .link-back-to-login {
    width: 8em;
    text-decoration: underline;
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: #3DD28F; }
  .SignUp-container .img-format-sign {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-top: 1.7em;
    max-width: 500px; }

.survey-container {
  background-image: url("./../assets/Cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow-y: auto; }
  .survey-container .form-container {
    height: auto;
    width: 100%; }
    .survey-container .form-container .img-format-sign {
      background-color: rgba(255, 255, 255, 0.65);
      display: block;
      margin: 0 auto;
      width: 78%;
      max-width: 600px;
      min-width: 490px;
      margin-top: 30px;
      border-top-left-radius: 70px;
      border-top-right-radius: 70px; }
    .survey-container .form-container .title-wrapper {
      background-color: rgba(255, 255, 255, 0.65);
      display: flex;
      margin: 0 auto;
      width: 78%;
      max-width: 600px;
      min-width: 490px;
      margin-top: 30px;
      border-top-left-radius: 70px;
      border-top-right-radius: 70px;
      height: 150px;
      justify-content: center;
      align-items: center; }
      .survey-container .form-container .title-wrapper .survey-head {
        font-size: 2.5em;
        text-align: center;
        font-family: "MuseoSans700";
        padding: 32px 30px 20px;
        max-width: 460px; }
    .survey-container .form-container .sign-up-wrapper {
      background-color: white;
      border-bottom-left-radius: 70px;
      border-bottom-right-radius: 70px;
      margin-left: auto;
      margin-right: auto;
      border: none;
      font-size: 14px;
      width: 78%;
      max-width: 600px;
      min-width: 490px; }
      .survey-container .form-container .sign-up-wrapper .form__group {
        background-size: auto;
        width: auto;
        max-width: 100%; }
        .survey-container .form-container .sign-up-wrapper .form__group .form__control {
          padding: 40px;
          padding-bottom: 100px; }
          .survey-container .form-container .sign-up-wrapper .form__group .form__control .checkbox-grid-container {
            position: relative; }
            .survey-container .form-container .sign-up-wrapper .form__group .form__control .checkbox-grid-container .label {
              width: 80%;
              min-width: 90px;
              padding-bottom: 10px;
              margin-right: 0; }
            @media (min-width: 0) and (max-width: 600px) {
              .survey-container .form-container .sign-up-wrapper .form__group .form__control .checkbox-grid-container {
                width: 85%;
                min-width: 270px; } }
            @media (min-width: 0) and (max-width: 400px) {
              .survey-container .form-container .sign-up-wrapper .form__group .form__control .checkbox-grid-container {
                width: 100%; }
                .survey-container .form-container .sign-up-wrapper .form__group .form__control .checkbox-grid-container .label {
                  width: 85%; } }
          .survey-container .form-container .sign-up-wrapper .form__group .form__control .hidden {
            visibility: hidden; }
          .survey-container .form-container .sign-up-wrapper .form__group .form__control .terms-container .terms-text {
            padding-left: 10px;
            align-items: center; }
            @media (min-width: 0) and (max-width: 582px) {
              .survey-container .form-container .sign-up-wrapper .form__group .form__control .terms-container .terms-text {
                padding-left: 0; } }
        @media (min-width: 0) and (max-width: 400px) {
          .survey-container .form-container .sign-up-wrapper .form__group {
            padding: 0px; }
            .survey-container .form-container .sign-up-wrapper .form__group .form__control {
              padding: 50px 15px;
              padding-bottom: 120px; } }
    @media (min-width: 0) and (max-width: 549px) {
      .survey-container .form-container .title-wrapper .survey-head {
        font-size: 2.2em;
        padding: 20px 30px; } }
    @media (min-width: 0) and (max-width: 530px) {
      .survey-container .form-container .sign-up-wrapper, .survey-container .form-container .img-format-sign, .survey-container .form-container .title-wrapper {
        width: 100%;
        min-width: 0px;
        border-radius: 0;
        margin-top: 0; } }
    .survey-container .form-container .btn-submit-sign {
      margin: 40px 0 80px; }
    @media (min-width: 0) and (max-width: 549px) {
      .survey-container .form-container {
        margin-top: 0;
        border-radius: 0; } }
    @media (min-width: 0) and (max-width: 530px) {
      .survey-container .form-container {
        width: 100%;
        min-width: 0px; }
        .survey-container .form-container .btn-submit-sign {
          margin: 0; }
          .survey-container .form-container .btn-submit-sign button {
            border-radius: 0;
            width: 100%;
            height: 60px; } }

.card {
  display: flex;
  align-items: center;
  min-height: 120px;
  margin: 0 auto 12px auto;
  padding: 0;
  border-radius: 10px !important; }
  .card .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 107px;
    min-width: 107px;
    margin: auto 8px; }
    .card .image-container .image-floater {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90%;
      min-width: 90%;
      overflow: hidden;
      border-radius: 10px; }
      .card .image-container .image-floater .card-image {
        max-width: 10%;
        max-height: 10%;
        transform: scale(10); }
  .card .info-section {
    display: flex;
    flex-grow: 1;
    margin-top: 5px; }
    .card .info-section .major-text {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      padding: 0 0 0 10px;
      margin: 15px 0; }
      .card .info-section .major-text .card-name-container {
        border-right: 1px solid #ddd;
        padding: 0 10px;
        flex-grow: 1; }
        .card .info-section .major-text .card-name-container .name {
          margin-bottom: auto;
          font-family: "MuseoSans700"; }
        .card .info-section .major-text .card-name-container .label {
          font-size: 1.1em;
          line-height: 16px !important;
          text-decoration: underline;
          margin-bottom: 10px; }
        @media (min-width: 768px) and (max-width: 820px) {
          .card .info-section .major-text .card-name-container .name {
            font-size: 1.0em; }
          .card .info-section .major-text .card-name-container .label {
            font-size: 1.1em; } }
      .card .info-section .major-text .card-info-container {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #ddd;
        padding: 0 10px;
        min-width: 200px; }
        .card .info-section .major-text .card-info-container .subinfo {
          display: flex;
          flex-wrap: nowrap; }
          .card .info-section .major-text .card-info-container .subinfo .value {
            margin-left: 1.6rem; }
      .card .info-section .major-text .sm {
        font-size: 1em;
        font-family: "MuseoSans300"; }
      .card .info-section .major-text .lg {
        font-size: 1.4em; }
    .card .info-section .minor-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-right: 1px solid #ddd;
      padding: 0 10px;
      margin: 15px 0; }
      .card .info-section .minor-text .md {
        font-size: 1.4em; }
      .card .info-section .minor-text .price-container {
        display: flex;
        flex-direction: row;
        margin-right: 10px; }
        .card .info-section .minor-text .price-container .price {
          font-family: "MuseoSans500"; }
        .card .info-section .minor-text .price-container .label {
          margin-left: 3px;
          font-family: "MuseoSans300"; }
      .card .info-section .minor-text .detail-container {
        display: flex;
        flex-direction: row; }
        .card .info-section .minor-text .detail-container .label {
          font-family: "MuseoSans300";
          color: #d75751;
          font-size: 1.1em; }
          .card .info-section .minor-text .detail-container .label.divider {
            margin-left: 0; }
          .card .info-section .minor-text .detail-container .label.hidden {
            display: none; }
    .card .info-section .card-actions {
      margin-left: auto;
      padding: 0 28px; }
      .card .info-section .card-actions .details-btn {
        padding: 0;
        min-height: 30px;
        min-width: 75px;
        background-color: #51d79a;
        text-transform: none; }
        .card .info-section .card-actions .details-btn .MuiButton-label-2 {
          font-size: 0.9em; }
  .card .mobil-ft {
    display: none !important; }
  @media (max-width: 767px) {
    .card {
      margin: 0 auto 7px auto;
      min-height: 100px;
      cursor: pointer; }
      .card .image-container {
        margin: 0;
        height: 100px;
        min-width: 100px;
        border-radius: 0; }
        .card .image-container .image-floater {
          height: 90%;
          min-width: 90%; }
          .card .image-container .image-floater .card-image {
            max-width: 10%;
            max-height: 10%;
            transform: scale(10); }
      .card .info-section {
        flex-wrap: wrap;
        margin-right: 10px;
        margin-left: 10px; }
        .card .info-section .major-text {
          width: 100%;
          padding: 0;
          flex-direction: column;
          border-style: none;
          margin: 0; }
          .card .info-section .major-text .card-name-container {
            display: block;
            border-style: none;
            border-bottom: 1px solid #ddd;
            padding: 0;
            margin-left: 10px;
            margin-bottom: 4px; }
            .card .info-section .major-text .card-name-container .name {
              margin-bottom: 4px; }
            .card .info-section .major-text .card-name-container .label {
              font-size: 1em;
              margin-bottom: 0;
              text-decoration: none; }
          .card .info-section .major-text .card-info-container {
            flex-direction: column;
            justify-content: space-between;
            max-width: 270px;
            border-style: none; }
            .card .info-section .major-text .card-info-container .subinfo {
              display: flex;
              flex-wrap: nowrap;
              min-width: 100px; }
              .card .info-section .major-text .card-info-container .subinfo .value {
                margin-left: 8px; }
          .card .info-section .major-text .lg {
            font-size: 1.3em; }
          .card .info-section .major-text .sm {
            font-size: 1em; }
        .card .info-section .minor-text {
          display: flex;
          flex-direction: row-reverse;
          flex-grow: 1;
          justify-content: space-between;
          padding: 0;
          border-style: none;
          margin: 0; }
          .card .info-section .minor-text .md {
            font-size: 1.2em; }
          .card .info-section .minor-text .price-container .price {
            font-family: "MuseoSans500"; }
          .card .info-section .minor-text .price-container .label {
            margin-left: 3px;
            font-family: "MuseoSans300"; }
          .card .info-section .minor-text .detail-container .label {
            font-size: 0.9em; }
          .card .info-section .minor-text .label {
            margin-left: 8px; }
        .card .info-section .card-actions {
          margin-left: 0;
          margin-left: auto;
          padding: 0; }
          .card .info-section .card-actions .details-btn {
            min-height: 30px;
            min-width: 70px;
            margin: 6px 0; }
            .card .info-section .card-actions .details-btn .MuiButton-label-2 {
              font-size: 0.85em; }
        .card .info-section .subinfo {
          font-size: 0.9em; } }
    @media (max-width: 767px) and (min-width: 0) and (max-width: 462px) {
      .card .info-section {
        margin-left: 0; }
        .card .info-section .major-text .lg {
          font-size: 1.1em; } }
  @media (max-width: 767px) {
      .card .web-ft {
        display: none !important; }
      .card .mobil-ft {
        display: block; } }
  @media (max-width: 424px) {
    .card {
      margin-left: 6px;
      margin-right: 6px; }
      .card .image-container {
        margin: 0;
        height: 106px;
        min-width: 106px;
        border-radius: 0; }
        .card .image-container .image-floater .card-image {
          max-width: 10%;
          max-height: 10%;
          transform: scale(10); }
      .card .info-section .major-text .card-name-container {
        padding-left: 0px;
        margin-left: 4px; }
        .card .info-section .major-text .card-name-container .name {
          margin-bottom: 2px; }
      .card .info-section .major-text .card-info-container {
        max-width: 200px;
        padding-left: 4px; }
        .card .info-section .major-text .card-info-container .subinfo {
          min-width: 80px; }
          .card .info-section .major-text .card-info-container .subinfo .label, .card .info-section .major-text .card-info-container .subinfo .value {
            font-size: 0.85em; }
          .card .info-section .major-text .card-info-container .subinfo .value {
            margin-left: 8px; }
      .card .info-section .minor-text .price, .card .info-section .minor-text .label {
        font-size: 1.1em; }
      .card .info-section .minor-text .label {
        margin-left: 4px; }
      .card .info-section .card-actions .details-btn {
        min-height: 25px;
        min-width: 70px; }
        .card .info-section .card-actions .details-btn .MuiButton-label-2 {
          font-size: 0.7em; } }
  .card .label {
    color: #51d79a; }

.modal {
  width: 90% !important;
  max-width: 800px !important;
  padding: 0 !important;
  background: rgba(0, 0, 0, 0) !important;
  border-style: none !important; }
  .modal .modal-scroll {
    overflow: scroll;
    background: rgba(0, 0, 0, 0);
    display: flex;
    height: 100vh; }
    .modal .modal-scroll .item-topbar {
      display: none;
      position: absolute;
      top: 0;
      top: env(safe-area-inset-top);
      width: 100%;
      z-index: 300; }
      .modal .modal-scroll .item-topbar .appbar {
        background-color: #fff; }
        .modal .modal-scroll .item-topbar .appbar .text {
          padding-left: 0; }
    .modal .modal-scroll .bottom-Appbar {
      display: none; }
    .modal .modal-scroll::-webkit-scrollbar {
      display: none; }
    .modal .modal-scroll .popup {
      position: relative;
      width: 100%;
      margin: auto; }
      .modal .modal-scroll .popup .modal-close-btn {
        position: absolute;
        right: 0; }
      .modal .modal-scroll .popup .modal-container {
        display: flex;
        flex-wrap: wrap;
        padding: 48px 1rem 1.4rem;
        background-color: #fff;
        border-radius: 20px; }
        .modal .modal-scroll .popup .modal-container .deal-display {
          width: 40%;
          padding-top: 20px;
          display: flex;
          flex-direction: column; }
          .modal .modal-scroll .popup .modal-container .deal-display .img-crop {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 240px;
            width: 240px;
            margin: 0 auto;
            overflow: hidden; }
            .modal .modal-scroll .popup .modal-container .deal-display .img-crop .modal-img {
              max-width: 10%;
              max-height: 10%;
              transform: scale(10);
              border-radius: 1.5px; }
          .modal .modal-scroll .popup .modal-container .deal-display .chip-wrapper {
            display: flex;
            justify-content: center; }
            .modal .modal-scroll .popup .modal-container .deal-display .chip-wrapper .chip {
              margin: 1rem 0.5rem 0; }
          .modal .modal-scroll .popup .modal-container .deal-display .modal-title {
            margin: 1rem 1rem 0;
            text-align: center; }
        .modal .modal-scroll .popup .modal-container .info {
          display: flex;
          flex-direction: column;
          width: 55%;
          margin-left: auto; }
          .modal .modal-scroll .popup .modal-container .info .top-info {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal .modal-scroll .popup .modal-container .info .top-info .grid-item {
              display: flex;
              flex-direction: column;
              flex-grow: 1 !important;
              border-right: 1px solid #ddd; }
              .modal .modal-scroll .popup .modal-container .info .top-info .grid-item:last-child {
                border-style: none; }
              .modal .modal-scroll .popup .modal-container .info .top-info .grid-item .label, .modal .modal-scroll .popup .modal-container .info .top-info .grid-item .value {
                margin: auto; }
          .modal .modal-scroll .popup .modal-container .info .details {
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal .modal-scroll .popup .modal-container .info .details .label {
              margin-bottom: 10px; }
            .modal .modal-scroll .popup .modal-container .info .details .grid-item {
              display: flex;
              flex-wrap: nowrap;
              padding: 6px 0; }
              .modal .modal-scroll .popup .modal-container .info .details .grid-item .gd {
                margin-left: 16px; }
          .modal .modal-scroll .popup .modal-container .info .reason-info {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal .modal-scroll .popup .modal-container .info .reason-info .value {
              margin-left: 16px; }
          .modal .modal-scroll .popup .modal-container .info .bottom {
            margin-bottom: 10px; }
            .modal .modal-scroll .popup .modal-container .info .bottom .label {
              margin-bottom: 10px; }
          .modal .modal-scroll .popup .modal-container .info .modal-buttons {
            margin-left: auto;
            margin-top: auto; }
            .modal .modal-scroll .popup .modal-container .info .modal-buttons button {
              width: 85px;
              height: 30px;
              margin-left: 30px; }
            .modal .modal-scroll .popup .modal-container .info .modal-buttons.hidden {
              display: none; }
        .modal .modal-scroll .popup .modal-container .lg {
          font-size: 1.7em;
          font-family: "MuseoSans700"; }
        .modal .modal-scroll .popup .modal-container .md {
          font-size: 1.2em;
          font-family: "MuseoSans300"; }
        .modal .modal-scroll .popup .modal-container .sm {
          font-size: 1.1em;
          font-family: "MuseoSans300"; }
  @media (max-width: 767px) {
    .modal {
      max-width: 500px !important; }
      .modal .modal-scroll .popup .modal-container {
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem; }
        .modal .modal-scroll .popup .modal-container .deal-display {
          width: 100%;
          padding-top: 0; }
          .modal .modal-scroll .popup .modal-container .deal-display .modal-title {
            margin: 0.6rem 0 0.4rem; }
          .modal .modal-scroll .popup .modal-container .deal-display .chip-wrapper .chip {
            margin: 0 0.5rem 0.4rem; }
        .modal .modal-scroll .popup .modal-container .info {
          width: 100%; }
        .modal .modal-scroll .popup .modal-container .web-ft {
          display: none; }
        .modal .modal-scroll .popup .modal-container .lg {
          font-size: 1.6em; } }
  @media (min-width: 0) and (max-width: 500px) {
    .modal {
      width: 100vw !important; }
      .modal .modal-scroll {
        padding-bottom: 0; }
        .modal .modal-scroll .item-topbar {
          display: block; }
        .modal .modal-scroll .bottom-Appbar {
          display: block; }
        .modal .modal-scroll .popup {
          padding-bottom: 0;
          margin: 0; }
          .modal .modal-scroll .popup .modal-close-btn {
            display: none; }
          .modal .modal-scroll .popup .modal-container {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            height: auto;
            margin: 40px 0 0;
            padding-bottom: 112px;
            padding-top: 56px;
            padding-left: 1rem;
            padding-right: 1rem; }
            .modal .modal-scroll .popup .modal-container .info .modal-buttons {
              display: none; } }

.deals-container {
  font-family: 'MuseoSans300', 'sans-serif';
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  margin-left: 250px;
  margin-top: 112px; }
  @media (min-width: 550px) and (max-width: 1024px) {
    .deals-container {
      margin-left: 50px; } }
  @media (min-width: 0) and (max-width: 549px) {
    .deals-container {
      margin-left: 0; } }
  @media (min-width: 0) and (max-width: 549px) {
    .deals-container {
      margin-top: 56px;
      margin-bottom: 120px; }
      .deals-container.table {
        margin-bottom: 75px; } }
  .deals-container .deals-list {
    margin: 0 0 80px; }
    .deals-container .deals-list .empty-text {
      display: flex;
      width: 100%; }
      .deals-container .deals-list .empty-text .text {
        margin: auto;
        opacity: 0.6;
        margin-top: 100px; }
        @media (max-width: 424px) {
          .deals-container .deals-list .empty-text .text {
            font-size: 1.5em;
            margin-top: 40px; } }
    .deals-container .deals-list .result-label {
      margin: 4px 10px 8px;
      font-size: 1.4em;
      opacity: 0.5; }
      .deals-container .deals-list .result-label.sm {
        font-size: 0.8em; }
    @media (min-width: 0) and (max-width: 549px) {
      .deals-container .deals-list {
        margin-top: 50px;
        margin-bottom: 0; }
        .deals-container .deals-list.lg-margin {
          margin-top: 90px; } }
  .deals-container .add-deal-btn-container {
    width: 100%;
    display: flex;
    margin-bottom: 2rem; }
    .deals-container .add-deal-btn-container .add-deal-btn {
      margin: 0 2rem 0 auto; }
  .deals-container .tool-tip-container {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px; }
  @media (min-width: 1280px) {
    .deals-container .deals-list, .deals-container .add-deal-btn-container {
      padding-right: 400px; } }
  @media (max-width: 767px) {
    .deals-container .add-deal-btn-container {
      display: none; } }
  @media (min-width: 768px) {
    .deals-container .tool-tip-container {
      display: none; } }
  @media (min-width: 0) and (max-width: 549px) {
    .deals-container .tool-tip-container {
      display: none; }
    .deals-container .bottom-Appbar {
      display: block; } }

.card-buyer {
  display: flex;
  align-items: center;
  min-height: 120px;
  margin: 0 auto 12px auto;
  padding: 0;
  border-radius: 10px !important; }
  .card-buyer .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 107px;
    min-width: 107px;
    margin: auto 8px; }
    .card-buyer .image-container .image-floater {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90%;
      min-width: 90%;
      overflow: hidden;
      border-radius: 10px; }
      .card-buyer .image-container .image-floater .card-image {
        max-width: 10%;
        max-height: 10%;
        transform: scale(10); }
  .card-buyer .info-section {
    display: flex;
    flex-grow: 1;
    margin-top: 5px; }
    .card-buyer .info-section .major-text {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      padding: 0 0 0 10px;
      margin: 15px 0; }
      .card-buyer .info-section .major-text .card-name-container {
        border-right: 1px solid #ddd;
        padding: 0 10px;
        flex-grow: 1; }
        .card-buyer .info-section .major-text .card-name-container .name {
          margin-bottom: auto;
          font-family: "MuseoSans700"; }
        .card-buyer .info-section .major-text .card-name-container .label {
          font-size: 1.1em;
          line-height: 16px !important;
          text-decoration: underline;
          margin-bottom: 10px; }
        @media (min-width: 768px) and (max-width: 820px) {
          .card-buyer .info-section .major-text .card-name-container .name {
            font-size: 1.0em; }
          .card-buyer .info-section .major-text .card-name-container .label {
            font-size: 1.1em; } }
      .card-buyer .info-section .major-text .card-info-container {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #ddd;
        padding: 0 10px;
        min-width: 200px; }
        .card-buyer .info-section .major-text .card-info-container .subinfo {
          display: flex;
          flex-wrap: nowrap; }
          .card-buyer .info-section .major-text .card-info-container .subinfo .value {
            margin-left: auto; }
      .card-buyer .info-section .major-text .sm {
        font-size: 1em;
        font-family: "MuseoSans300"; }
      .card-buyer .info-section .major-text .lg {
        font-size: 1.4em; }
    .card-buyer .info-section .minor-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-right: 1px solid #ddd;
      padding: 0 10px;
      margin: 15px 0; }
      .card-buyer .info-section .minor-text .md {
        font-size: 1.4em; }
      .card-buyer .info-section .minor-text .price-container {
        display: flex;
        flex-direction: row;
        margin-right: 10px; }
        .card-buyer .info-section .minor-text .price-container .price {
          font-family: "MuseoSans500"; }
        .card-buyer .info-section .minor-text .price-container .label {
          margin-left: 3px;
          font-family: "MuseoSans300"; }
      .card-buyer .info-section .minor-text .detail-container {
        display: flex;
        flex-direction: row; }
        .card-buyer .info-section .minor-text .detail-container .label {
          font-family: "MuseoSans300";
          font-size: 1.1em;
          color: #d75751; }
          .card-buyer .info-section .minor-text .detail-container .label.divider {
            margin-left: 0; }
          .card-buyer .info-section .minor-text .detail-container .label.hidden {
            display: none; }
    .card-buyer .info-section .card-actions {
      margin-left: auto;
      padding: 0 9px; }
      .card-buyer .info-section .card-actions .details-btn {
        padding: 0;
        min-height: 30px;
        min-width: 75px;
        background-color: #51d79a;
        text-transform: none; }
        .card-buyer .info-section .card-actions .details-btn .MuiButton-label-2 {
          font-size: 0.9em; }
  .card-buyer .mobil-ft {
    display: none !important; }
  @media (max-width: 767px) {
    .card-buyer {
      margin: 0 auto 7px auto;
      min-height: 100px;
      cursor: pointer; }
      .card-buyer .image-container {
        margin: 0;
        height: 100px;
        min-width: 100px;
        border-radius: 0; }
        .card-buyer .image-container .image-floater {
          height: 90%;
          min-width: 90%; }
          .card-buyer .image-container .image-floater .card-image {
            max-width: 10%;
            max-height: 10%;
            transform: scale(10); }
      .card-buyer .info-section {
        flex-wrap: wrap;
        margin-right: 10px;
        margin-left: 10px; }
        .card-buyer .info-section .major-text {
          width: 100%;
          padding: 0;
          flex-direction: column;
          border-style: none;
          margin: 0; }
          .card-buyer .info-section .major-text .card-name-container {
            display: block;
            border-style: none;
            border-bottom: 1px solid #ddd;
            padding: 0;
            margin-left: 10px;
            margin-bottom: 4px; }
            .card-buyer .info-section .major-text .card-name-container .name {
              margin-bottom: 4px; }
            .card-buyer .info-section .major-text .card-name-container .label {
              font-size: 1em;
              margin-bottom: 0;
              text-decoration: none; }
          .card-buyer .info-section .major-text .card-info-container {
            flex-direction: column;
            justify-content: space-between;
            max-width: 270px;
            border-style: none; }
            .card-buyer .info-section .major-text .card-info-container .subinfo {
              display: flex;
              flex-wrap: nowrap;
              min-width: 100px; }
              .card-buyer .info-section .major-text .card-info-container .subinfo .value {
                margin-left: 8px; }
          .card-buyer .info-section .major-text .lg {
            font-size: 1.3em; }
          .card-buyer .info-section .major-text .sm {
            font-size: 1em; }
        .card-buyer .info-section .minor-text {
          display: flex;
          flex-direction: row-reverse;
          flex-grow: 1;
          justify-content: space-between;
          padding: 0;
          border-style: none;
          margin: 0; }
          .card-buyer .info-section .minor-text .md {
            font-size: 1.2em; }
          .card-buyer .info-section .minor-text .price-container .price {
            font-family: "MuseoSans500"; }
          .card-buyer .info-section .minor-text .price-container .label {
            margin-left: 3px;
            font-family: "MuseoSans300"; }
          .card-buyer .info-section .minor-text .detail-container .label {
            font-size: 0.9em; }
          .card-buyer .info-section .minor-text .label {
            margin-left: 8px; }
        .card-buyer .info-section .card-actions {
          margin-left: 0;
          margin-left: auto;
          padding: 0; }
          .card-buyer .info-section .card-actions .details-btn {
            min-height: 30px;
            min-width: 70px;
            margin: 6px 0; }
            .card-buyer .info-section .card-actions .details-btn .MuiButton-label-2 {
              font-size: 0.85em; }
        .card-buyer .info-section .subinfo {
          font-size: 0.9em; } }
    @media (max-width: 767px) and (min-width: 0) and (max-width: 462px) {
      .card-buyer .info-section {
        margin-left: 0; }
        .card-buyer .info-section .major-text .lg {
          font-size: 1.1em; } }
  @media (max-width: 767px) {
      .card-buyer .web-ft {
        display: none !important; }
      .card-buyer .mobil-ft {
        display: block; } }
  @media (max-width: 424px) {
    .card-buyer {
      margin-left: 6px;
      margin-right: 6px; }
      .card-buyer .image-container {
        margin: 0;
        height: 106px;
        min-width: 106px;
        border-radius: 0; }
        .card-buyer .image-container .image-floater .card-image {
          max-width: 10%;
          max-height: 10%;
          transform: scale(10); }
      .card-buyer .info-section .major-text .card-name-container {
        padding-left: 0px;
        margin-left: 4px; }
        .card-buyer .info-section .major-text .card-name-container .name {
          margin-bottom: 2px; }
      .card-buyer .info-section .major-text .card-info-container {
        max-width: 200px;
        padding-left: 4px; }
        .card-buyer .info-section .major-text .card-info-container .subinfo {
          min-width: 80px; }
          .card-buyer .info-section .major-text .card-info-container .subinfo .label, .card-buyer .info-section .major-text .card-info-container .subinfo .value {
            font-size: 0.85em; }
          .card-buyer .info-section .major-text .card-info-container .subinfo .value {
            margin-left: 8px; }
      .card-buyer .info-section .minor-text .price, .card-buyer .info-section .minor-text .label {
        font-size: 1.1em; }
      .card-buyer .info-section .minor-text .label {
        margin-left: 4px; }
      .card-buyer .info-section .card-actions .details-btn {
        min-height: 25px;
        min-width: 70px; }
        .card-buyer .info-section .card-actions .details-btn .MuiButton-label-2 {
          font-size: 0.7em; } }
  .card-buyer .label {
    color: #51d79a; }

.modal-buyer {
  width: 90% !important;
  max-width: 800px !important;
  padding: 0 !important;
  background: rgba(0, 0, 0, 0) !important;
  border-style: none !important; }
  .modal-buyer .modal-scroll {
    overflow: scroll;
    background: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    height: 100vh; }
    .modal-buyer .modal-scroll .item-topbar {
      display: none;
      position: absolute;
      top: 0;
      top: env(safe-area-inset-top);
      width: 100%;
      z-index: 300; }
      .modal-buyer .modal-scroll .item-topbar .appbar {
        background-color: #fff; }
        .modal-buyer .modal-scroll .item-topbar .appbar .text {
          padding-left: 0; }
    .modal-buyer .modal-scroll .bottom-Appbar {
      display: none; }
    .modal-buyer .modal-scroll::-webkit-scrollbar {
      display: none; }
    .modal-buyer .modal-scroll .popup {
      position: relative;
      width: 100%;
      margin: auto;
      margin-bottom: 0; }
      .modal-buyer .modal-scroll .popup .modal-close-btn {
        position: absolute;
        right: 0; }
      .modal-buyer .modal-scroll .popup .modal-container {
        display: flex;
        flex-wrap: wrap;
        padding: 48px 1rem 1.4rem;
        background-color: #fff;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px; }
        .modal-buyer .modal-scroll .popup .modal-container.rounded {
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          margin-bottom: auto; }
        .modal-buyer .modal-scroll .popup .modal-container .deal-display {
          width: 40%;
          display: flex;
          flex-direction: column; }
          .modal-buyer .modal-scroll .popup .modal-container .deal-display .img-crop {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 220px;
            max-width: 220px;
            height: 50vw;
            width: 50vw;
            margin: 0 auto;
            overflow: hidden; }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .img-crop .modal-img {
              max-width: 10%;
              max-height: 10%;
              transform: scale(10);
              border-radius: 1.5px; }
          .modal-buyer .modal-scroll .popup .modal-container .deal-display .chip-wrapper {
            display: flex;
            justify-content: center; }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .chip-wrapper .chip {
              margin: 0 0.5rem; }
          .modal-buyer .modal-scroll .popup .modal-container .deal-display .modal-title {
            text-align: center;
            font-size: 1.6em;
            margin-bottom: 7px; }
          .modal-buyer .modal-scroll .popup .modal-container .deal-display .modal-seller {
            text-align: center;
            color: #51d79a;
            text-decoration: underline;
            font-size: 1.3em;
            margin: 6px 0; }
          .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers {
            margin: 10px 0 0;
            border: 1px solid #51d79a;
            padding: 10px 20px 0;
            border-radius: 10px; }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls .quantity-input {
              width: 50px;
              text-align: center;
              border-style: none;
              font-size: 1.5em; }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls input::-webkit-outer-spin-button,
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls input::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0;
              /* <-- Apparently some margin are still there even though it's hidden */ }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls .order-button {
              height: 32px;
              width: 32px;
              border-radius: 100px;
              border: 2px solid #51d79a;
              background: rgba(0, 0, 0, 0); }
              .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .quantity-controls .order-button .step-button {
                color: #51d79a;
                transform: scale(2); }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .needby {
              margin: 15px 0; }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .date {
              border: 2px solid #51d79a;
              border-radius: 10px;
              transform: scale(0.8); }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .total {
              font-size: 1.5em;
              color: #51d79a;
              font-family: "MuseoSans500"; }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .transporation-text {
              font-family: "MuseoSans500";
              margin: 8px 0 10px; }
        .modal-buyer .modal-scroll .popup .modal-container .info {
          display: flex;
          flex-direction: column;
          width: 55%;
          margin-left: auto; }
          .modal-buyer .modal-scroll .popup .modal-container .info .top-info {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal-buyer .modal-scroll .popup .modal-container .info .top-info .grid-item {
              display: flex;
              flex-direction: column;
              flex-grow: 1 !important;
              border-right: 1px solid #ddd; }
              .modal-buyer .modal-scroll .popup .modal-container .info .top-info .grid-item:last-child {
                border-style: none; }
              .modal-buyer .modal-scroll .popup .modal-container .info .top-info .grid-item .label, .modal-buyer .modal-scroll .popup .modal-container .info .top-info .grid-item .value {
                margin: auto; }
          .modal-buyer .modal-scroll .popup .modal-container .info .details {
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal-buyer .modal-scroll .popup .modal-container .info .details .label {
              margin-bottom: 10px; }
            .modal-buyer .modal-scroll .popup .modal-container .info .details .grid-item {
              display: flex;
              flex-wrap: nowrap;
              padding: 6px 0; }
              .modal-buyer .modal-scroll .popup .modal-container .info .details .grid-item .gd {
                margin-left: 16px; }
          .modal-buyer .modal-scroll .popup .modal-container .info .reason-info {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-bottom: 13px;
            margin-bottom: 13px; }
            .modal-buyer .modal-scroll .popup .modal-container .info .reason-info .value {
              margin-left: 16px; }
          .modal-buyer .modal-scroll .popup .modal-container .info .bottom {
            height: 280px; }
            .modal-buyer .modal-scroll .popup .modal-container .info .bottom .label {
              margin-bottom: 10px; }
            @media (max-width: 767px) {
              .modal-buyer .modal-scroll .popup .modal-container .info .bottom {
                height: auto; } }
          .modal-buyer .modal-scroll .popup .modal-container .info .modal-buttons {
            margin-left: auto;
            margin-top: auto; }
            .modal-buyer .modal-scroll .popup .modal-container .info .modal-buttons button {
              width: 85px;
              height: 30px;
              margin-left: 30px; }
        .modal-buyer .modal-scroll .popup .modal-container .lg {
          font-size: 1.7em;
          font-family: "MuseoSans700"; }
        .modal-buyer .modal-scroll .popup .modal-container .md {
          font-size: 1.2em;
          font-family: "MuseoSans300"; }
        .modal-buyer .modal-scroll .popup .modal-container .sm {
          font-size: 1.1em;
          font-family: "MuseoSans300"; }
    .modal-buyer .modal-scroll .submit-button-container {
      width: 100%;
      margin-bottom: auto; }
      .modal-buyer .modal-scroll .submit-button-container .submit {
        width: 100%;
        height: 50px;
        font-size: 1.5em;
        font-family: "MuseoSans700" !important;
        text-transform: none;
        text-align: center;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px; }
      .modal-buyer .modal-scroll .submit-button-container.hidden {
        display: none; }
  @media (max-width: 767px) {
    .modal-buyer {
      max-width: 500px !important; }
      .modal-buyer .modal-scroll .popup .modal-container {
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem; }
        .modal-buyer .modal-scroll .popup .modal-container .deal-display {
          width: 100%;
          padding-top: 0; }
          .modal-buyer .modal-scroll .popup .modal-container .deal-display .modal-title {
            max-width: 300px;
            margin: 0 auto 7px; }
          .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers {
            margin: 10px 0; }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .label {
              margin-left: 30px; }
        .modal-buyer .modal-scroll .popup .modal-container .info {
          width: 100%; }
        .modal-buyer .modal-scroll .popup .modal-container .web-ft {
          display: none; }
        .modal-buyer .modal-scroll .popup .modal-container .lg {
          font-size: 1.6em; } }
  @media (min-width: 0) and (max-width: 500px) {
    .modal-buyer {
      width: 100vw !important; }
      .modal-buyer .modal-scroll {
        padding-bottom: 50px; }
        .modal-buyer .modal-scroll .item-topbar {
          display: block; }
        .modal-buyer .modal-scroll .bottom-Appbar {
          display: block; }
        .modal-buyer .modal-scroll .popup {
          padding-bottom: 0;
          margin: 0; }
          .modal-buyer .modal-scroll .popup .modal-close-btn {
            display: none; }
          .modal-buyer .modal-scroll .popup .modal-container {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            height: auto;
            margin: 40px 0 0;
            padding-bottom: 112px;
            padding-top: 56px;
            padding-left: 1rem;
            padding-right: 1rem; }
            .modal-buyer .modal-scroll .popup .modal-container .deal-display .order-controllers .label {
              margin-left: 0; }
      .modal-buyer .submit-button-container {
        display: none;
        position: fixed;
        bottom: 0; }
        .modal-buyer .submit-button-container .submit {
          border-radius: 0 !important;
          font-size: 1.3em !important; } }

.deals-container {
  font-family: 'MuseoSans300', 'sans-serif'; }
  .deals-container .add-deal-btn-container {
    width: 100%;
    display: flex;
    margin-bottom: 2rem; }
    .deals-container .add-deal-btn-container .add-deal-btn {
      margin: 0 2rem 0 auto; }
  .deals-container .tool-tip-container {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px; }
  @media (max-width: 767px) {
    .deals-container .add-deal-btn-container {
      display: none; } }
  @media (min-width: 768px) {
    .deals-container .tool-tip-container {
      display: none; } }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes slide-in-left {
  0% {
    transform: translate(-100px, 0); }
  100% {
    transform: translate(0, 0); } }

@-moz-keyframes slide-in-left {
  0% {
    transform: translate(-100px, 0); }
  100% {
    transform: translate(0, 0); } }

@-o-keyframes slide-in-left {
  0% {
    transform: translate(-100px, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-in-left {
  0% {
    transform: translate(-100px, 0); }
  100% {
    transform: translate(0, 0); } }

@-webkit-keyframes slide-in-right {
  0% {
    transform: translate(100px, 0); }
  100% {
    transform: translate(0, 0); } }

@-moz-keyframes slide-in-right {
  0% {
    transform: translate(100px, 0); }
  100% {
    transform: translate(0, 0); } }

@-o-keyframes slide-in-right {
  0% {
    transform: translate(100px, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-in-right {
  0% {
    transform: translate(100px, 0); }
  100% {
    transform: translate(0, 0); } }

@-webkit-keyframes slide-in-up {
  0% {
    transform: translate(0, 15px); }
  100% {
    transform: translate(0, 0); } }

@-moz-keyframes slide-in-up {
  0% {
    transform: translate(0, 15px); }
  100% {
    transform: translate(0, 0); } }

@-o-keyframes slide-in-up {
  0% {
    transform: translate(0, 15px); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-in-up {
  0% {
    transform: translate(0, 15px); }
  100% {
    transform: translate(0, 0); } }

@-webkit-keyframes slide-in-down {
  0% {
    transform: translate(0, -15px); }
  100% {
    transform: translate(0, 0); } }

@-moz-keyframes slide-in-down {
  0% {
    transform: translate(0, -15px); }
  100% {
    transform: translate(0, 0); } }

@-o-keyframes slide-in-down {
  0% {
    transform: translate(0, -15px); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-in-down {
  0% {
    transform: translate(0, -15px); }
  100% {
    transform: translate(0, 0); } }

@-webkit-keyframes anim-shadow {
  0% {
    box-shadow: -0.1px 0.1px 0.1px 0.1px rgba(0, 0, 0, 0); }
  100% { } }

@-moz-keyframes anim-shadow {
  0% {
    box-shadow: -0.1px 0.1px 0.1px 0.1px rgba(0, 0, 0, 0); }
  100% { } }

@-o-keyframes anim-shadow {
  0% {
    box-shadow: -0.1px 0.1px 0.1px 0.1px rgba(0, 0, 0, 0); }
  100% { } }

@keyframes anim-shadow {
  0% {
    box-shadow: -0.1px 0.1px 0.1px 0.1px rgba(0, 0, 0, 0); }
  100% { } }

@-webkit-keyframes grow-skill {
  0% {
    transform: scale(0.92); }
  100% { } }

@-moz-keyframes grow-skill {
  0% {
    transform: scale(0.92); }
  100% { } }

@-o-keyframes grow-skill {
  0% {
    transform: scale(0.92); }
  100% { } }

@keyframes grow-skill {
  0% {
    transform: scale(0.92); }
  100% { } }

@-webkit-keyframes grow-card {
  0% {
    transform: scale(0.98); }
  100% { } }

@-moz-keyframes grow-card {
  0% {
    transform: scale(0.98); }
  100% { } }

@-o-keyframes grow-card {
  0% {
    transform: scale(0.98); }
  100% { } }

@keyframes grow-card {
  0% {
    transform: scale(0.98); }
  100% { } }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

@keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight; }

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: hinge;
  animation-name: hinge; }

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

.jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

@keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

@-webkit-keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp; }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

@font-face {
  font-family: 'MyFont100';
  src: local("MyFont"), url(./../assets/fonts/MuseoSans-100.woff) format("woff"); }

@font-face {
  font-family: 'MuseoSans300';
  src: local("MuseoSans300"), url(./../assets/fonts/MuseoSans-300.woff) format("woff"); }

@font-face {
  font-family: 'MuseoSans300Italic';
  src: local("MuseoSans300Italic"), url(./../assets/fonts/MuseoSans-300Italic.woff) format("woff"); }

@font-face {
  font-family: 'MuseoSans500';
  src: local("MuseoSans500"), url(./../assets/fonts/MuseoSans-500.woff) format("woff"); }

@font-face {
  font-family: 'MuseoSans700';
  src: local("MuseoSans700"), url(./../assets/fonts/MuseoSans-700.woff) format("woff"); }
